import React, { useEffect, useState } from 'react'
import { useLoaderData } from 'react-router-dom'

import { LongTermVolumes, PlanPosition } from '../types'

import PurchaseBasicInfo from './components/PurchaseBasicInfo'
import PurchaseBudgetFunds from './components/PurchaseBudgetFunds'
import PurchaseDatesInfo from './components/PurchaseDatesInfo'
import PurchaseItemsSection from './components/PurchaseItemsSection'
import PurchaseMethod from './components/PurchaseMethod'
import PurchasePaymentBreakdown from './components/PurchasePaymentBreakdown'

import Footer from './Footer'
import PurchaseChangeInfo from './components/PurchaseChangeInfo'
import { PlanPositionRow, PlanPositionWithRows } from './types'

export const loadPlanPosition = async ({
    params,
}: any): Promise<PlanPositionWithRows | null> => {
    const action = params.action
    const isDraft = action === 'new' || action === 'draft'

    let url = 'https://itzakupki.ru/api/backend/v1/'

    if (isDraft) {
        url += 'plan_position_draft'
    } else {
        url += 'plan_positions'
    }

    url += '/?plan_guid=' + params.plan_guid

    if (action !== 'new') {
        url += '&guid=' + params.pos_guid
    }

    return fetch(url, {
        method: action === 'new' ? 'POST' : 'GET',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
        },
    })
        .then((response) => {
            if (response.ok) {
                return response.json()
            } else {
                throw new Error('Failed to fetch plan positions')
            }
        })
        .then(
            (data) =>
                (isDraft
                    ? action === 'new'
                        ? data.plan_pos
                        : data
                    : data[0]) as Promise<PlanPosition>
        )
        .then((pos) => {
            if (action === 'new') {
                return { pos: pos, rows: [] }
            }

            return fetch(
                'https://itzakupki.ru/api/backend/v1/plan_position_rows' +
                    (isDraft ? '_draft' : '') +
                    `/?plan_guid=${params.plan_guid}&pos_guid=${params.pos_guid}`,
                {
                    method: 'GET',
                    credentials: 'include',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            )
                .then((response) => {
                    if (response.ok) {
                        return response.json() as Promise<PlanPositionRow[]>
                    } else {
                        throw new Error('Failed to fetch plan position rows')
                    }
                })
                .then((rows) => ({ pos, rows }))
        })
        .catch(() => null)
}

function getStartDate(pos: PlanPosition): Date {
    let date: Date
    if (pos.purchase_planned_date !== null) {
        date = new Date(pos.purchase_planned_date)
    } else if (
        pos.purchase_period_year !== null &&
        pos.purchase_period_month !== null
    ) {
        date = new Date(pos.purchase_period_year, pos.purchase_period_month - 1)
    } else {
        date = new Date()
    }
    return date
}

function getUpdatedLongTermVolumes(
    pos: PlanPosition,
    existingVolumes: LongTermVolumes | null,
    yearsInRange: number[]
): LongTermVolumes {
    if (existingVolumes === null) {
        return {
            volume: 0,
            currency: {
                code: pos.currency_code,
                digital_code: pos.currency_digital_code,
                name: pos.currency_name,
            },
            details: {
                items: yearsInRange.map((year) => ({
                    summ: 0,
                    year: year,
                })),
            },
        }
    } else {
        const existingYears = existingVolumes.details.items.map(
            (item) => item.year
        )

        const newItems = yearsInRange
            .filter((year) => !existingYears.includes(year))
            .map((year) => ({
                summ: 0,
                year: year,
            }))

        const existingItems = existingVolumes.details.items.filter((item) =>
            yearsInRange.includes(item.year)
        )

        const allItems = [...newItems, ...existingItems].sort(
            (a, b) => a.year - b.year
        )

        const volume = existingItems
            .map((item) => item.summ)
            .reduce((a, b) => a + b)

        return {
            volume: volume,
            currency: {
                code: pos.currency_code,
                digital_code: pos.currency_digital_code,
                name: pos.currency_name,
            },
            details: {
                items: allItems,
            },
        }
    }
}

function onDateUpdate(
    pos: PlanPosition,
    isStart: boolean,
    newDate: Date,
    isFullDate?: boolean
): PlanPosition {
    const getYearsInRange = (start: Date, end: Date) => {
        const startYear = start.getFullYear()
        const endYear = end.getFullYear()
        return Array.from(
            { length: endYear - startYear + 1 },
            (_, i) => startYear + i
        )
    }

    const yearsInRange = getYearsInRange(
        isStart ? newDate : getStartDate(pos),
        isStart ? new Date(pos.contract_end_date) : newDate
    )

    const newLongTermVolumes =
        yearsInRange.length > 1
            ? getUpdatedLongTermVolumes(
                  pos,
                  pos.long_term_volumes,
                  yearsInRange
              )
            : null
    const newLongTermSmbVolumes =
        yearsInRange.length > 1
            ? getUpdatedLongTermVolumes(
                  pos,
                  pos.long_term_smb_volumes,
                  yearsInRange
              )
            : null

    return {
        ...pos,
        purchase_planned_date: isStart
            ? isFullDate
                ? newDate.toISOString()
                : null
            : pos.purchase_planned_date,
        purchase_period_month: isStart
            ? isFullDate
                ? null
                : newDate.getMonth() + 1
            : pos.purchase_period_month,
        purchase_period_year: isStart
            ? isFullDate
                ? null
                : newDate.getFullYear()
            : pos.purchase_period_year,
        contract_end_date: isStart
            ? pos.contract_end_date
            : newDate.toISOString(),
        long_term_volumes: newLongTermVolumes,
        long_term_smb_volumes: newLongTermSmbVolumes,
    }
}

const PlanPositionEditPage: React.FC = () => {
    const data = useLoaderData() as PlanPositionWithRows
    const [pos, setPos] = useState(data.pos)

    const handleSave = () => {
        // okato и purchase_period_month должны быть string
        Object.defineProperty(pos, 'okato', { value: '98000000000' })
        Object.defineProperty(pos, 'purchase_period_month', { value: '8' })

        fetch(
            'https://itzakupki.ru/api/backend/v1/plan_position_draft/?plan_guid=' +
                encodeURIComponent(pos.plan_guid) +
                '&guid=' +
                encodeURIComponent(pos.guid),
            {
                method: 'PUT',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(pos),
            }
        )
            .then((response) => {
                if (response.ok) {
                    // success
                    return response.json()
                } else {
                    throw new Error('Неверный почтовый адрес или пароль')
                }
            })
            .catch((error) => undefined)
            .finally(() => undefined)
    }

    const [purchaseMethod, setPurchaseMethod] = useState('')
    const [basisSingleSupplier, setBasisSingleSupplier] = useState('')
    const [areParticipantsSME, setAreParticipantsSME] = useState(false)
    const [isPlannedThirdYear, setIsPlannedThirdYear] = useState(false)
    const [isInnovativeProduct, setIsInnovativeProduct] = useState(false)
    const [isExcludedForSME, setIsExcludedForSME] = useState(false)
    const [purchaseCategory, setPurchaseCategory] = useState('')

    useEffect(() => {
        if (purchaseMethod === 'single_supplier') {
            setBasisSingleSupplier('todo')
        } else {
            setBasisSingleSupplier('')
        }
    }, [purchaseMethod])

    useEffect(() => {
        if (isExcludedForSME) {
            setPurchaseCategory('todo')
        } else {
            setPurchaseCategory('')
        }
    }, [isExcludedForSME])

    let purchasePlannedDate: Date
    if (pos.purchase_planned_date !== null) {
        purchasePlannedDate = new Date(pos.purchase_planned_date)
    } else if (
        pos.purchase_period_year !== null &&
        pos.purchase_period_month !== null
    ) {
        purchasePlannedDate = new Date(
            pos.purchase_period_year,
            pos.purchase_period_month - 1
        )
    } else {
        purchasePlannedDate = new Date()
    }
    const contractEndDate = new Date(pos.contract_end_date)

    return (
        <div className="h-full flex flex-col">
            <header className="p-5"></header>

            {/* disable form */}
            <fieldset disabled={pos.status === 'P'}>
                <main className="grow overflow-y-auto flex flex-col gap-y-5 p-5">
                    {pos.status !== 'N' && <PurchaseChangeInfo />}

                    <PurchaseBasicInfo
                        name={pos.contract_subject}
                        onNameChange={(name) => {
                            setPos((prev) => ({
                                ...prev,
                                contract_subject: name,
                            }))
                        }}
                        minRequirements={pos.minimum_requirements || ''}
                        onMinRequirementsChange={(minRequirements) => {
                            setPos((prev) => ({
                                ...prev,
                                minimum_requirements: minRequirements,
                            }))
                        }}
                        contractPriceReason={pos.order_pricing || ''}
                        onContractPriceReasonChange={(contractPriceReason) => {
                            setPos((prev) => ({
                                ...prev,
                                order_pricing: contractPriceReason,
                            }))
                        }}
                        nmcd={
                            pos.maximum_contract_price
                                ? Number(pos.maximum_contract_price)
                                : 0
                        }
                        onNmcdChange={(newNmcd) => {
                            setPos((prev) => ({
                                ...prev,
                                maximum_contract_price: newNmcd.toString(),
                            }))
                        }}
                        currency={pos.currency_code}
                        onCurrencyChange={(newCurrencyCode) => {
                            if (newCurrencyCode === 'RUB') {
                                setPos((prev) => ({
                                    ...prev,
                                    currency_code: newCurrencyCode,
                                    currency_digital_code: 643,
                                    exchange_rate: null,
                                    exchange_rate_date: null,
                                }))
                            } else {
                                let newCurrencyDigitalCode = 0
                                if (newCurrencyCode === 'USD') {
                                    newCurrencyDigitalCode = 840
                                } else if (newCurrencyCode === 'EUR') {
                                    newCurrencyDigitalCode = 978
                                } else if (newCurrencyCode === 'CNY') {
                                    newCurrencyDigitalCode = 156
                                }

                                setPos((prev) => ({
                                    ...prev,
                                    currency_code: newCurrencyCode,
                                    currency_digital_code:
                                        newCurrencyDigitalCode,
                                    exchange_rate: 1,
                                    exchange_rate_date:
                                        new Date().toISOString(),
                                }))
                            }
                        }}
                        isNmcdWithTax={pos.is_nmcd_with_tax}
                        onNmcdWithTaxChange={(newNmcdWithTax) => {
                            setPos((prev) => ({
                                ...prev,
                                is_nmcd_with_tax: newNmcdWithTax,
                            }))
                        }}
                        currencyExchangeDate={
                            pos.exchange_rate_date
                                ? new Date(pos.exchange_rate_date)
                                : undefined
                        }
                        onCurrencyExchangeDateChange={(newExchangeRateDate) => {
                            setPos((prev) => ({
                                ...prev,
                                exchange_rate_date:
                                    newExchangeRateDate.toISOString(),
                            }))
                        }}
                        currencyExchangeRate={pos.exchange_rate ?? 1}
                        onCurrencyExchangeRateChange={(newExchangeRate) => {
                            setPos((prev) => ({
                                ...prev,
                                exchange_rate:
                                    newExchangeRate < 0 ? 0 : newExchangeRate,
                            }))
                        }}
                    />
                    <PurchaseBudgetFunds
                        isBudgetFundsIncluded={
                            pos.maximum_contract_price_from_budget
                        }
                        onBudgetFundsIncludedChange={(value) => {
                            setPos((prev) => ({
                                ...prev,
                                maximum_contract_price_from_budget: value,
                            }))
                        }}
                    />
                    <PurchaseDatesInfo
                        start={purchasePlannedDate}
                        onStartDateChange={(date, isFullDate) => {
                            setPos((prev) =>
                                onDateUpdate(
                                    prev,
                                    true /* isStart */,
                                    date,
                                    isFullDate
                                )
                            )
                        }}
                        end={contractEndDate}
                        onEndDateChange={(date) => {
                            setPos((prev) =>
                                onDateUpdate(prev, false /* isStart */, date)
                            )
                        }}
                        isStartFullDate={pos.purchase_planned_date !== null}
                    />

                    {pos.long_term_volumes !== null &&
                        pos.long_term_smb_volumes != null && (
                            <div className="flex flex-row gap-x-4">
                                <div className="basis-1/2">
                                    <PurchasePaymentBreakdown
                                        title="Информация об объемах оплаты долгосрочного договора"
                                        yearlyPayments={pos.long_term_volumes}
                                        onYearlyPaymentsChange={(
                                            year,
                                            value
                                        ) => {
                                            if (pos.long_term_volumes) {
                                                const item =
                                                    pos.long_term_volumes.details.items.find(
                                                        (y) => y.year === year
                                                    )
                                                if (item) {
                                                    setPos((prev) => {
                                                        if (
                                                            prev.long_term_volumes ===
                                                            null
                                                        ) {
                                                            return prev
                                                        }

                                                        const newVolume =
                                                            prev
                                                                .long_term_volumes
                                                                .volume -
                                                            item.summ +
                                                            value

                                                        return {
                                                            ...prev,
                                                            long_term_volumes: {
                                                                ...prev.long_term_volumes,
                                                                volume: newVolume,
                                                                details: {
                                                                    items: prev.long_term_volumes.details.items.map(
                                                                        (
                                                                            dt
                                                                        ) => {
                                                                            if (
                                                                                dt.year ===
                                                                                year
                                                                            ) {
                                                                                return {
                                                                                    ...dt,
                                                                                    summ: value,
                                                                                }
                                                                            }

                                                                            return dt
                                                                        }
                                                                    ),
                                                                },
                                                            },
                                                        }
                                                    })
                                                }
                                            }
                                        }}
                                        currency={pos.currency_code}
                                        currencyExchangeDate={
                                            pos.exchange_rate_date
                                                ? new Date(
                                                      pos.exchange_rate_date
                                                  )
                                                : undefined
                                        }
                                        currencyExchangeRate={
                                            pos.exchange_rate ?? undefined
                                        }
                                    />
                                </div>
                                <div className="basis-1/2">
                                    <PurchasePaymentBreakdown
                                        title="Информация об объемах привлечения субъектов малого и среднего предпринимательства"
                                        yearlyPayments={
                                            pos.long_term_smb_volumes
                                        }
                                        onYearlyPaymentsChange={(
                                            year,
                                            value
                                        ) => {
                                            if (pos.long_term_smb_volumes) {
                                                const item =
                                                    pos.long_term_smb_volumes.details.items.find(
                                                        (y) => y.year === year
                                                    )
                                                if (item) {
                                                    setPos((prev) => {
                                                        if (
                                                            prev.long_term_smb_volumes ===
                                                            null
                                                        ) {
                                                            return prev
                                                        }

                                                        const newVolume =
                                                            prev
                                                                .long_term_smb_volumes
                                                                .volume -
                                                            item.summ +
                                                            value

                                                        return {
                                                            ...prev,
                                                            long_term_smb_volumes:
                                                                {
                                                                    ...prev.long_term_smb_volumes,
                                                                    volume: newVolume,
                                                                    details: {
                                                                        items: prev.long_term_smb_volumes.details.items.map(
                                                                            (
                                                                                dt
                                                                            ) => {
                                                                                if (
                                                                                    dt.year ===
                                                                                    year
                                                                                ) {
                                                                                    return {
                                                                                        ...dt,
                                                                                        summ: value,
                                                                                    }
                                                                                }

                                                                                return dt
                                                                            }
                                                                        ),
                                                                    },
                                                                },
                                                        }
                                                    })
                                                }
                                            }
                                        }}
                                        currency={pos.currency_code}
                                        currencyExchangeDate={
                                            pos.exchange_rate_date
                                                ? new Date(
                                                      pos.exchange_rate_date
                                                  )
                                                : undefined
                                        }
                                        currencyExchangeRate={
                                            pos.exchange_rate ?? undefined
                                        }
                                    />
                                </div>
                            </div>
                        )}

                    <PurchaseMethod
                        method={purchaseMethod}
                        onMethodChange={setPurchaseMethod}
                        basisSingleSupplier={basisSingleSupplier}
                        onBasisSingleSupplierChange={setBasisSingleSupplier}
                        areParticipantsSME={areParticipantsSME}
                        onAreParticipantsSMEChange={setAreParticipantsSME}
                        isPlannedThirdYear={isPlannedThirdYear}
                        onIsPlannedThirdYearChange={setIsPlannedThirdYear}
                        isInnovativeProduct={isInnovativeProduct}
                        onIsInnovativeProductChange={setIsInnovativeProduct}
                        isExcludedForSME={isExcludedForSME}
                        onIsExcludedForSMEChange={setIsExcludedForSME}
                        category={purchaseCategory}
                        onCategoryChange={setPurchaseCategory}
                    />

                    <PurchaseItemsSection positionRows={data.rows} />
                </main>
            </fieldset>

            <Footer handleSave={handleSave} />
        </div>
    )
}

export default PlanPositionEditPage
