import React from 'react'
import { isRouteErrorResponse, useRouteError } from 'react-router-dom'

const ErrorPage: React.FC = () => {
    const error = useRouteError()
    const message = isRouteErrorResponse(error) ? error.statusText : null

    return (
        <div className="flex flex-col w-full h-full text-white justify-center items-center">
            <h1>Произошла ошибка!</h1>
            <p>{message == 'Not Found' ? 'Страница не найдена' : message}</p>
        </div>
    )
}

export default ErrorPage
