import React, { FormEvent, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useAuth } from '../../hooks/auth'
import { jwtDecode } from 'jwt-decode'
import isEmail from 'validator/lib/isEmail'

type ApiResponse = {
    detail: string
    access_token: string
}

const LoginForm: React.FC = () => {
    const { login } = useAuth()
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [error, setError] = useState<string | null>(null)
    const [isValid, setValid] = useState(false)
    const [isLoading, setLoading] = useState(false)

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()

        setLoading(true)

        fetch('https://itzakupki.ru/api/auth/v1/users/login/', {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                email: email,
                password: password,
            }),
        })
            .then((response) => {
                if (response.ok) {
                    // success
                    return response.json()
                } else {
                    throw new Error('Неверный почтовый адрес или пароль')
                }
            })
            .then((result: ApiResponse) => {
                login({
                    token: result.access_token,
                    object: jwtDecode(result.access_token),
                })
            })
            .catch((serverError) => {
                setPassword('')
                setError(serverError.message)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    useEffect(() => {
        setValid(isEmail(email) && password.length > 3)
    }, [email, password])

    return (
        <>
            <h5 className="mb-5 text-lg text-center text-white">
                Добро пожаловать!
            </h5>
            <form method="post" onSubmit={handleSubmit}>
                <fieldset disabled={isLoading}>
                    <div className="flex flex-col mb-4">
                        <label className="mb-1 text-sm text-zinc-500">
                            Адрес электронной почты
                        </label>
                        <input
                            name="email"
                            type="email"
                            className="p-2 rounded-md border-0 text-sm bg-neutral-900 text-white"
                            autoComplete="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required={true}
                        />
                    </div>
                    <div className="flex flex-col mb-4">
                        <label className="mb-1 text-sm text-zinc-500">
                            Пароль
                        </label>
                        <input
                            name="password"
                            type="password"
                            className="p-2 rounded-md border-0 text-sm bg-neutral-900 text-white"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required={true}
                        />
                    </div>
                    <p>
                        <Link
                            to={`restore`}
                            className="text-sm text-sky-600 hover:text-sky-400"
                        >
                            Забыли пароль?
                        </Link>
                    </p>
                    <button
                        className="w-full my-4 p-2 bg-fuchsia-600 rounded-full shadow-md text-sm text-white disabled:bg-fuchsia-900 disabled:text-gray-400"
                        disabled={!isValid}
                    >
                        Вход
                    </button>
                </fieldset>
            </form>
            <p className="mb-0 text-zinc-500 text-sm">
                Организация отсутствует в системе?{' '}
                <Link to={`search`} className="text-sky-600 hover:text-sky-400">
                    Зарегистрировать
                </Link>
            </p>
            {error && (
                <p className="mb-0 mt-2 text-sm text-red-600" role="alert">
                    {error}
                </p>
            )}
        </>
    )
}

export default LoginForm
