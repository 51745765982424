import React from 'react'

const OrgHandbookOkved2Page: React.FC = () => {
    return (
        <div className="flex flex-col text-white h-full">
            <div className="bg-[#111111]">
                <div className="w-auto h-20 mx-6 flex flex-row gap-x-5 ">
                    <div className="h-20 my-auto content-center ">
                        <p className="text-sm text-white">
                            ОКВЭД 2 - Общероссийский классификатор видов
                            экономической деятельности
                        </p>
                    </div>
                </div>
            </div>

            <div className="flex flex-1 justify-center max-h-[calc(100vh_-_80px)] p-5">
                <div className="flex flex-col h-full w-[1260px] space-y-10 bg-slate-600">
                    {/* // body */}
                    <div className="flex bg-[#181818] h-[calc(100vh_-_120px)]">
                        {/* table */}
                        <div className="flex flex-col h-full w-full">
                            {/* toolbar */}
                            <div className="flex flex-row gap-1 text-white p-2 bg-[#333033] rounded-t-lg items-center">
                                <div className="w-6 h-6"></div>
                            </div>

                            {/* name */}
                            <div className="flex flex-row gap-1 h-10 bg-[#2A282B] text-[#8A8A8A] text-[10px] px-2">
                                <div className="flex w-10 justify-center items-center text-center">
                                    №
                                </div>
                                <div className="flex flex-col w-28 justify-center items-center text-center">
                                    <span>Классификация</span>
                                    <span>по ОКВЭД2</span>
                                </div>
                                <div className="grid flex-1 place-items-center text-center">
                                    Наименование справочника
                                </div>
                            </div>

                            {/* data */}
                            <div className="flex flex-col overflow-y-auto gap-1 py-1 bg-[#1D1C1E] h-dvh">
                                {/* Вывод списка ОКВЭД2 */}
                                <>
                                    <div className="flex flex-row cursor-pointer gap-1 mx-1 px-1 bg-[#2A282B] hover:bg-[#373538] text-white text-[11px] rounded">
                                        <div className="flex w-10 items-center justify-center text-center text-[#8A8A8A]">
                                            1
                                        </div>
                                        <div className="flex flex-col w-28 justify-center items-start text-center">
                                            58.29
                                        </div>
                                        <div className="flex flex-1 items-center justify-left px-2 py-2">
                                            Издание прочих программных продуктов
                                        </div>
                                    </div>

                                    <div className="flex flex-row cursor-pointer gap-1 mx-1 px-1 bg-[#2A282B] hover:bg-[#373538] text-white text-[11px] rounded">
                                        <div className="flex w-10 items-center justify-center text-center text-[#8A8A8A]">
                                            2
                                        </div>
                                        <div className="flex flex-col w-28 justify-center items-start text-center">
                                            59.12
                                        </div>
                                        <div className="flex flex-1 items-center justify-left px-2 py-2">
                                            Деятельность монтажно-компоновочная
                                            в области производства кинофильмов,
                                            видеофильмов и телевизионных
                                            программ
                                        </div>
                                    </div>

                                    <div className="flex flex-row cursor-pointer gap-1 mx-1 px-1 bg-[#2A282B] hover:bg-[#373538] text-white text-[11px] rounded">
                                        <div className="flex w-10 items-center justify-center text-center text-[#8A8A8A]">
                                            3
                                        </div>
                                        <div className="flex flex-col w-28 justify-center items-start text-center">
                                            35.11.1
                                        </div>
                                        <div className="flex flex-1 items-center justify-left px-2 py-2">
                                            Производство электроэнергии
                                            тепловыми электростанциями, в том
                                            числе деятельность по обеспечению
                                            работоспособности электростанций
                                        </div>
                                    </div>
                                </>
                            </div>

                            {/* buttons */}
                            <div className="flex flex-row gap-1 text-white p-2 bg-[#333033] rounded-b-lg h-10 space-x-2"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OrgHandbookOkved2Page
