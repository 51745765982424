import React, { useState } from 'react'

import { Link } from 'react-router-dom'
import {
    AcademicCapIcon,
    Cog6ToothIcon,
    WrenchIcon,
} from '@heroicons/react/24/outline'

import logo from '../../../../images/logo.png'

import MenuTextItem from './components/MenuTextItem'
import MenuIconItem from './components/MenuIconItem'
import SubMenuItem from './components/SubMenuItem'

import { TextLink } from './types'
import './NavBar.css'
import { useAuth } from '../../../../hooks/auth'

const MENU_KEY_ORG = 'org'
const MENU_KEY_USER = 'user'

const SUB_MENU_ITEMS = new Map<string, TextLink[]>([
    [
        MENU_KEY_ORG,
        [
            { link: 'org/home', text: 'Организация' },
            { link: 'org/users', text: 'Пользователи' },
            { link: 'org/structure', text: 'Структура' },
            { link: 'org/roles', text: 'Роли' },
            { link: 'org/methods', text: 'Способы закупки' },
            { link: 'org/lists', text: 'Перечни' },
            { link: 'org/handbook', text: 'Справочники' },
        ],
    ],
    [
        MENU_KEY_USER,
        [
            { link: 'home', text: 'Главная страница' },
            { link: 'plans', text: 'План закупок' },
        ],
    ],
])

const NavBar: React.FC = () => {
    const initialState = window.location.hash.startsWith('#/hub/org')
        ? MENU_KEY_ORG
        : MENU_KEY_USER
    const [currentMenuItem, setCurrentMenuItem] = useState(initialState)
    const { user } = useAuth()

    return (
        <>
            <div className="h-full flex flex-col p-1 items-center bg-[#181818]">
                <img src={logo} alt="Логотип" className="w-10 h-10 mb-4" />
                <ul className="flex flex-col gap-y-1 grow items-center">
                    <MenuTextItem
                        text="Орг"
                        isActive={currentMenuItem === MENU_KEY_ORG}
                        onClick={() => {
                            setCurrentMenuItem(MENU_KEY_ORG)
                        }}
                    />
                    <li className="divider" />
                    <MenuTextItem
                        text="223"
                        isActive={currentMenuItem === MENU_KEY_USER}
                        onClick={() => {
                            setCurrentMenuItem(MENU_KEY_USER)
                        }}
                    />
                    <li className="divider" />
                    <MenuIconItem
                        link=""
                        icon={<AcademicCapIcon className="w-4 h-4" />}
                    />
                    <MenuIconItem
                        link=""
                        icon={<WrenchIcon className="w-4 h-4" />}
                    />
                </ul>
                <div className="flex w-10 h-10 rounded-full items-center justify-center bg-amber-700 text-sm text-white">
                    ИИ
                </div>
            </div>
            <div className="h-full flex flex-col min-w-36 p-2 bg-[#262427]">
                <div className="mt-1 mb-6 text-white uppercase leading-none">
                    Virtual
                    <br />
                    Office
                </div>
                <ul className="flex flex-col grap-y-1 grow">
                    {(SUB_MENU_ITEMS.get(currentMenuItem) as TextLink[]).map(
                        (item, index) => (
                            <SubMenuItem
                                key={index}
                                link={item.link}
                                text={item.text}
                            />
                        )
                    )}
                </ul>
                <Link
                    to={`/hub/org/users/${user?.object.user_id}`}
                    className="flex flex-row h-10 items-center p-2.5 rounded text-xs text-zinc-500 hover:bg-[#464447] hover:text-white"
                >
                    <div className="grow">
                        {user?.object.login.split('@')[0]}
                    </div>{' '}
                    <Cog6ToothIcon className="h-5 w-5" />
                </Link>
            </div>
        </>
    )
}

export default NavBar
