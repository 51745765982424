import React, { useState, useEffect } from 'react'
import Add from '../../../../components/Toolbar/Add'
import { useLoaderData, useNavigate } from 'react-router-dom'
import { User } from './types'
import { Structure } from '../Structure/types'
import './style.css'

type ApiResponse = {
    message: User[]
}

export const loadUsers = async (): Promise<User[]> => {
    return fetch('https://itzakupki.ru/api/backend/v1/organization/users', {
        method: 'GET',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
        },
    })
        .then((response) => {
            if (response.ok) {
                return response.json()
            } else {
                throw new Error('Failed to fetch organization')
            }
        })
        .then((response: ApiResponse) => response.message)
        .catch((e) => {
            console.error(e)
            return []
        })
}

const loadStructure = async (): Promise<Structure[]> => {
    return fetch('https://itzakupki.ru/api/backend/v1/organization_struct', {
        method: 'GET',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
        },
    })
        .then((response) => {
            if (response.ok) {
                return response.json()
            } else {
                throw new Error('Failed to fetch organization_struct')
            }
        })
        .catch((e) => {
            console.error(e)
        })
}

const OrgUsersPage: React.FC = () => {
    const usersLoad = useLoaderData() as User[]
    const [usersData, setUsersData] = useState<User[]>(usersLoad)
    const [userUpdate, setUserUpdate] = useState<User | null>(null)

    const navigate = useNavigate()

    const [structData, setStructData] = useState<Structure[]>([])
    useEffect(() => {
        const loadData = async () => {
            const data = await loadStructure()
            setStructData(data)
        }
        loadData()
    }, [])

    const getNameStruct = (struct_guid: string) => {
        const nameSctruct = structData.find((s) => s.guid === struct_guid)
        return nameSctruct?.struct_short_name || '-'
    }

    return (
        <div className="flex flex-col text-white h-full">
            <div className="bg-[#111111]">
                <div className="w-auto h-20 mx-6 flex flex-row gap-x-5 ">
                    <div className="w-60 h-20 my-auto content-center ">
                        <p className="text-sm text-white">
                            Реестр пользователей
                        </p>
                    </div>
                </div>
            </div>

            <div className="flex flex-1 justify-center max-h-[calc(100vh_-_80px)] p-5">
                <div className="flex flex-col h-full w-[1260px] space-y-10 bg-slate-600">
                    {/* // body */}
                    <div className="flex bg-[#181818] h-[calc(100vh_-_120px)]">
                        {/* table */}
                        <div className="flex flex-col w-full min-w-fit">
                            {/* toolbar */}
                            <div className="flex flex-row gap-1 text-white p-2 h-10 bg-[#333033] rounded-t-lg">
                                <Add color={'#FFFFFF'} />
                            </div>

                            {/* name */}
                            <div className="flex flex-row gap-1 h-10 bg-[#2A282B] text-[#8A8A8A] text-[10px] px-2">
                                <div className="grid w-10 place-items-center text-center">
                                    №
                                </div>
                                <div className="grid w-10 place-items-center text-center"></div>
                                <div className="grid flex-1 place-items-center text-center">
                                    Фамилия Имя Отчество
                                </div>
                                <div className="grid flex-1 place-items-center text-center">
                                    Должность
                                </div>
                                <div className="grid flex-1 place-items-center text-center">
                                    Подразделение
                                </div>
                                <div className="grid flex-1 place-items-center text-center">
                                    Роль
                                </div>
                                <div className="grid w-24 place-items-center text-center">
                                    Статус
                                </div>
                            </div>

                            {/* data */}
                            <div className="flex flex-col overflow-y-auto gap-1 py-1 h-dvh bg-[#1D1C1E]">
                                {/* Вывод пользователей */}

                                {usersData.map((user, index) => (
                                    <div
                                        key={index}
                                        className="flex flex-row cursor-pointer gap-1 mx-1 px-1 bg-[#2A282B] hover:bg-[#373538] text-white text-[11px] rounded items-center"
                                        onClick={() =>
                                            navigate(
                                                `/hub/org/users/${user.guid}/edit`
                                            )
                                        }
                                    >
                                        <div className="flex h-10 w-10 items-center justify-center text-center text-[#8A8A8A]">
                                            {index + 1}
                                        </div>
                                        <div className="flex h-10 w-10 items-center justify-start text-center">
                                            <div className="text-white bg-[#B04E24] w-7 h-7 rounded-full mx-auto my-auto content-center">
                                                {user.last_name.slice(0, 1) +
                                                    user.first_name.slice(0, 1)}
                                            </div>
                                        </div>
                                        <div className="flex h-10 flex-1 items-center justify-start text-left px-2 py-2">
                                            {user.last_name +
                                                ' ' +
                                                user.first_name +
                                                ' ' +
                                                user.patronymic}
                                        </div>
                                        <div className="flex h-10 flex-1 items-center justify-center px-2">
                                            {user.position}
                                        </div>
                                        <div className="flex h-10 flex-1 items-center justify-center px-2">
                                            {getNameStruct(
                                                user.struct_guid
                                                    ? user.struct_guid
                                                    : ''
                                            )}
                                        </div>
                                        <div className="flex h-10 flex-1 items-center justify-center px-2">
                                            {user.role_guid}
                                        </div>
                                        <div className="flex h-12 w-24 items-center justify-center px-2 rounded gap-1">
                                            <div className="flex w-5 h-5 rounded-full items-center justify-center text-white bg-[#187A33]">
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 24 24"
                                                    id="check"
                                                    className="h-4 w-4"
                                                >
                                                    <path
                                                        fill="#FFFFFF"
                                                        d="M18.71,7.21a1,1,0,0,0-1.42,0L9.84,14.67,6.71,11.53A1,1,0,1,0,5.29,13l3.84,3.84a1,1,0,0,0,1.42,0l8.16-8.16A1,1,0,0,0,18.71,7.21Z"
                                                    ></path>
                                                </svg>
                                            </div>
                                            Активный
                                        </div>
                                    </div>
                                ))}
                            </div>

                            {/* buttons */}
                            <div className="flex flex-row gap-1 text-white p-2 bg-[#333033] rounded-b-lg h-10 space-x-2">
                                <button
                                    className="rounded-full px-4 h-6 text-[12.8px] bg-[#494849] hover:bg-[#656365]"
                                    onClick={() =>
                                        navigate('/hub/org/users/new/edit')
                                    }
                                >
                                    + Создать пользователя
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OrgUsersPage
