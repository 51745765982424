import React, { useState } from 'react'
import { useLoaderData, useNavigate } from 'react-router-dom'
import { PurchaseMethod, PurchaseMethodFromEis } from './types'
import './style.css'

const formatDate = (isoString: string): string => {
    const date = new Date(isoString)
    const day = date.getDate().toString().padStart(2, '0')
    const month = (date.getMonth() + 1).toString().padStart(2, '0')
    const year = date.getFullYear()

    return `${day}.${month}.${year}`
}

const TYPAL_KIND = new Map<string, string>([
    ['TSK', 'Конкурс'],
    ['TSA', 'Аукцион'],
    ['TSZK', 'Запрос котировок'],
    ['TSZP', 'Запрос предложений'],
    ['TSEP', 'Закупка у единственного поставщика (подрядчика, исполнителя)'],
])

export const loadPurchaseMethods = async (): Promise<PurchaseMethod> => {
    return fetch('https://itzakupki.ru/api/backend/v1/purchase_method', {
        method: 'GET',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
        },
    })
        .then((response) => {
            if (response.ok) {
                return response.json()
            } else {
                throw new Error('Failed to fetch organization')
            }
        })
        .catch((e) => {
            console.error(e)
        })
}

const OrgPurchaseMethodsPage: React.FC = () => {
    const navigate = useNavigate()
    const purchaseMethodsLoad = useLoaderData() as PurchaseMethod[]
    const [purchaseMethodsData, setPurchaseMethodsData] =
        useState<PurchaseMethod[]>(purchaseMethodsLoad)
    const [popupDeletePurchaseMethod, setPopupDeletePurchaseMethod] =
        useState(false)
    const [popupPurchaseMethodFromEis, setPopupPurchaseMethodFromEis] =
        useState(false)
    const [isDeleting, setIsDeleting] = useState(false) // Состояние для анимации загрузки
    const [selectedPurchaseMethodGuid, setSelectedPurchaseMethodGuid] =
        useState('')
    const [isLoading, setLoading] = useState(false) // Состояние для анимации загрузки
    const [isAdding, setIsAdding] = useState(false) // Состояние для анимации загрузки
    const [purchaseMethodFromEis, setPurchaseMethodFromEis] = useState<
        PurchaseMethodFromEis[]
    >([])

    const getNameTypalKind = (typal_kind: string): string => {
        return TYPAL_KIND.get(typal_kind) || ''
    }

    const getNamePurchaseMethod = (method_guid: string) => {
        const nameMethod = purchaseMethodsData.find(
            (s) => s.guid === method_guid
        )
        return nameMethod?.purchase_method_name || '-'
    }

    const handlePopupDeletePurchaseMethod = (method_guid: string) => {
        setPopupDeletePurchaseMethod(!popupDeletePurchaseMethod)
        // setErrorsInput({})
        setSelectedPurchaseMethodGuid(method_guid)
    }

    const handleDeletePurchaseMethod = async () => {
        setIsDeleting(true) // Включаем анимацию загрузки

        if (!selectedPurchaseMethodGuid) return

        try {
            const response = await fetch(
                `https://itzakupki.ru/api/backend/v1/purchase_method/${selectedPurchaseMethodGuid}`,
                {
                    method: 'DELETE',
                    credentials: 'include',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            )
            if (!response.ok) {
                throw new Error('Ошибка при удалении')
            }
            setPopupDeletePurchaseMethod(false)
            setPurchaseMethodsData((prevStruct) =>
                prevStruct.filter(
                    (struct) => struct.guid !== selectedPurchaseMethodGuid
                )
            )
            setSelectedPurchaseMethodGuid('')
            console.log('Способ успешно удален')
        } catch (error) {
            console.error('Ошибка удаления способа:', error)
        } finally {
            setIsDeleting(false) // Отключаем анимацию загрузки
        }
    }

    const getPurchaseMethodFromEis = async () => {
        setLoading(true)

        const urlEis = `https://itzakupki.ru/api/backend/v1/purchase_method_from_eis`
        const urlEisMSP = `https://itzakupki.ru/api/backend/v1/purchase_method_from_eis_msp`

        try {
            // Выполняем оба запроса параллельно
            const [responseEis, responseEisMSP] = await Promise.all([
                fetch(urlEis, {
                    method: 'GET',
                    credentials: 'include',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }),
                fetch(urlEisMSP, {
                    method: 'GET',
                    credentials: 'include',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }),
            ])

            // Проверяем ответы
            if (!responseEis.ok || !responseEisMSP.ok) {
                throw new Error(
                    `Failed to get purchase methods. Status: ${responseEis.status}, ${responseEisMSP.status}`
                )
            }

            // Получаем данные из обоих запросов
            const dataEis = await responseEis.json()
            const dataEisMSP = await responseEisMSP.json()

            // Объединяем результаты
            const combinedData = [...dataEis, ...dataEisMSP]

            // Обновляем состояние
            setPurchaseMethodFromEis(combinedData)
        } catch (error) {
            console.error('Error while getting purchase methods:', error)
        } finally {
            setLoading(false) // Сбрасываем состояние загрузки
        }
    }

    const addPurchaseMethodFromEis = async (
        purchaseItem: PurchaseMethod
    ): Promise<void> => {
        const url = `https://itzakupki.ru/api/backend/v1/purchase_method`

        try {
            const response = await fetch(url, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(purchaseItem), // Добавлено тело запроса
            })

            if (!response.ok) {
                throw new Error(
                    `Failed to create new position from. Status: ${response.status}`
                )
            }
            console.log('Position created successfully')
        } catch (error) {
            console.error('Error while creating position:', error)
            setIsAdding(false)
        } finally {
        }
    }

    const updatePurchaseMethodFromEis = async (
        purchaseItem: PurchaseMethod
    ): Promise<void> => {
        const url = `https://itzakupki.ru/api/backend/v1/purchase_method/${purchaseItem.guid}`

        try {
            const response = await fetch(url, {
                method: 'PUT',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(purchaseItem), // Добавлено тело запроса
            })

            if (!response.ok) {
                throw new Error(
                    `Failed to create new position from. Status: ${response.status}`
                )
            }
            console.log('Position created successfully')
        } catch (error) {
            console.error('Error while creating position:', error)
            setIsAdding(false)
        } finally {
        }
    }

    const mapPurchaseMethodFromEisToPurchaseMethod = (
        item: PurchaseMethodFromEis
    ): PurchaseMethod => {
        return {
            guid: item.guid,
            purchase_method_code: item.code,
            purchase_method_name: item.name,
            purchase_method_short_name: '',
            typal: item.typal,
            typal_kind: item.typal_kind,
            is_electronic: item.is_electronic,
            closed_purchase: item.closed_purchase,
            competitive: item.competitive,
            has_phases: item.has_phases,
            purchase_smb_type: item.name.includes(
                'малого и среднего предпринимательства'
            ),
            minimum_deadline_days: 0, // Предположительно значение отсутствует в исходных данных
            days_type: !item.name.includes(
                'малого и среднего предпринимательства'
            ), // Пример значения по умолчанию
            maximum_contract_price: 0, // Пример значения по умолчанию
            maximum_amount_request_security: 0, // Пример значения по умолчанию
            maximum_amount_contract_security: 0, // Пример значения по умолчанию
            required_document_tz: false, // Пример значения по умолчанию
            required_document_nmcd: false, // Пример значения по умолчанию
            required_document_en: false, // Пример значения по умолчанию
            required_document_contract_project: false, // Пример значения по умолчанию
            modification_description: item.user_modification_reason || '',
            modification_date: item.change_date_time || '',
        }
    }

    // Функция для загрузки данных и обновления таблицы
    const reloadPurchaseMethods = async () => {
        setLoading(true) // Включаем индикатор загрузки
        try {
            const data =
                (await loadPurchaseMethods()) as unknown as PurchaseMethod[] // Загружаем данные через API
            setPurchaseMethodsData(data) // Обновляем состояние таблицы
        } catch (error) {
            console.error('Error while reloading purchase methods:', error)
        } finally {
            setLoading(false) // Выключаем индикатор загрузки
        }
    }

    const handleAddClick = async () => {
        setIsAdding(true)
        try {
            const mappedItems = purchaseMethodFromEis.map(
                mapPurchaseMethodFromEisToPurchaseMethod
            )

            const tasks = mappedItems.map(async (item) => {
                const existingItem = purchaseMethodsData.find(
                    (existing) =>
                        existing.purchase_method_code ===
                        item.purchase_method_code
                )

                if (existingItem) {
                    console.log(
                        `Updating existing item with code: ${item.purchase_method_code}`
                    )
                    await updatePurchaseMethodFromEis({
                        ...item,
                        guid: existingItem.guid,
                    })
                } else {
                    console.log(
                        `Adding new item with code: ${item.purchase_method_code}`
                    )
                    await addPurchaseMethodFromEis(item)
                }
            })

            await Promise.all(tasks)

            console.log('All items processed successfully')

            // После добавления/обновления заново загружаем данные
            await reloadPurchaseMethods()

            setPurchaseMethodFromEis([])
        } catch (error) {
            console.error('Error while processing purchase methods:', error)
        } finally {
            setIsAdding(false)
            setPopupPurchaseMethodFromEis(false)
        }
    }

    const handlePopupPurchaseMethodFromEis = () => {
        getPurchaseMethodFromEis()
        setPopupPurchaseMethodFromEis(!popupPurchaseMethodFromEis)
    }

    const handleRemoveFromList = (guid: string) => {
        setPurchaseMethodFromEis((prev) =>
            prev.filter((item) => item.guid !== guid)
        )
    }

    return (
        <div className="flex flex-col text-white h-full">
            <div className="bg-[#111111]">
                <div className="w-auto h-20 mx-6 flex flex-row gap-x-5 ">
                    <div className="w-60 h-20 my-auto content-center ">
                        <p className="text-sm text-white">
                            Реестр способов закупки
                        </p>
                    </div>
                </div>
            </div>

            <div className="flex flex-1 justify-center max-h-[calc(100vh_-_80px)] p-5">
                <div className="flex flex-col h-full w-full space-y-10 bg-slate-600">
                    {/* // body */}
                    <div className="flex bg-[#181818] h-[calc(100vh_-_120px)]">
                        {/* table */}
                        <div className="flex flex-col w-full min-w-fit">
                            {/* toolbar */}
                            <div className="flex flex-row gap-1 text-white p-2 h-10 bg-[#333033] rounded-t-lg">
                                {/* Add */}
                                <button
                                    className="w-6 h-6 grid rounded-full place-items-center hover:bg-[#656365]"
                                    onClick={() =>
                                        navigate('/hub/org/methods/new/edit')
                                    }
                                    title="Добавить структуру"
                                >
                                    <svg
                                        className="w-3 h-3"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 24 24"
                                        id="plus"
                                    >
                                        <path
                                            fill="#FFFFFF"
                                            d="M19,11H13V5a1,1,0,0,0-2,0v6H5a1,1,0,0,0,0,2h6v6a1,1,0,0,0,2,0V13h6a1,1,0,0,0,0-2Z"
                                        ></path>
                                    </svg>
                                </button>

                                {/* Add */}
                                <button
                                    className="w-6 h-6 grid rounded-full place-items-center hover:bg-[#656365]"
                                    onClick={handlePopupPurchaseMethodFromEis}
                                    title="Добавить/Обновить из ЕИС"
                                >
                                    {isAdding ? (
                                        <svg
                                            className="animate-spin h-4 w-4 text-white"
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                        >
                                            <circle
                                                className="opacity-25"
                                                cx="12"
                                                cy="12"
                                                r="10"
                                                stroke="currentColor"
                                                strokeWidth="4"
                                            ></circle>
                                            <path
                                                className="opacity-75"
                                                fill="currentColor"
                                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                                            ></path>
                                        </svg>
                                    ) : (
                                        <svg
                                            className="w-3 h-3"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 22 22"
                                            id="update"
                                        >
                                            <path
                                                fill={'#FFFFFF'}
                                                d="M19.91,15.51H15.38a1,1,0,0,0,0,2h2.4A8,8,0,0,1,4,12a1,1,0,0,0-2,0,10,10,0,0,0,16.88,7.23V21a1,1,0,0,0,2,0V16.5A1,1,0,0,0,19.91,15.51ZM12,2A10,10,0,0,0,5.12,4.77V3a1,1,0,0,0-2,0V7.5a1,1,0,0,0,1,1h4.5a1,1,0,0,0,0-2H6.22A8,8,0,0,1,20,12a1,1,0,0,0,2,0A10,10,0,0,0,12,2Z"
                                            ></path>
                                        </svg>
                                    )}
                                </button>
                            </div>

                            {/* name */}
                            <div className="flex flex-row gap-1 h-10 bg-[#2A282B] text-[#8A8A8A] text-[10px] px-2">
                                <div className="grid w-10 place-items-center text-center">
                                    №
                                </div>
                                <div className="grid w-12 place-items-center text-center">
                                    Id способа
                                </div>
                                <div className="grid flex-1 min-w-80 place-items-center text-center">
                                    Наименование способа
                                </div>
                                <div className="grid w-28 place-items-center text-center">
                                    Статус
                                </div>
                                <div className="grid w-28 place-items-center text-center">
                                    Тип способа закупки
                                </div>
                                <div className="grid w-28 place-items-center text-center">
                                    Вид способа закупки
                                </div>
                                <div className="grid w-52 place-items-center text-center">
                                    Вид типового способа
                                </div>
                                <div className="grid w-28 place-items-center text-center">
                                    Тип конкурентности
                                </div>
                                <div className="grid w-28 place-items-center text-center">
                                    Форма проведения
                                </div>
                                <div className="grid w-28 place-items-center text-center">
                                    Ограничение по МСП
                                </div>
                                <div className="grid w-28 place-items-center text-center">
                                    Дата изменения
                                </div>
                                <div className="grid w-10 place-items-center text-center"></div>
                            </div>

                            {/* data */}
                            <div className="flex flex-col overflow-y-auto gap-1 py-1 bg-[#1D1C1E] h-dvh">
                                {/* Вывод пользователей */}
                                {purchaseMethodsData.map((method, index) => (
                                    <div
                                        key={method.guid}
                                        className="flex flex-row cursor-pointer gap-1 mx-1 px-1 bg-[#2A282B] hover:bg-[#373538] text-white text-[11px] rounded"
                                    >
                                        <div
                                            className="flex flex-row flex-1 gap-1 py-1"
                                            onClick={() => {
                                                navigate(
                                                    `/hub/org/methods/${method.guid}/edit`
                                                )
                                            }}
                                        >
                                            <div className="grid w-10 place-items-center text-center text-[#8A8A8A]">
                                                {index + 1}
                                            </div>
                                            <div className="grid w-12 place-items-center text-center">
                                                {method.purchase_method_code}
                                            </div>
                                            <div className="grid flex-1 place-items-center justify-items-start text-left px-2 py-2">
                                                {method.purchase_method_name}
                                            </div>
                                            <div className="flex h-12 w-28 items-center justify-center px-2 rounded gap-1">
                                                <div className="flex w-5 h-5 rounded-full items-center justify-center text-white bg-[#187A33]">
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        viewBox="0 0 24 24"
                                                        id="check"
                                                        className="h-4 w-4"
                                                    >
                                                        <path
                                                            fill="#FFFFFF"
                                                            d="M18.71,7.21a1,1,0,0,0-1.42,0L9.84,14.67,6.71,11.53A1,1,0,1,0,5.29,13l3.84,3.84a1,1,0,0,0,1.42,0l8.16-8.16A1,1,0,0,0,18.71,7.21Z"
                                                        ></path>
                                                    </svg>
                                                </div>
                                                Активный
                                            </div>
                                            <div className="grid w-28 place-items-center text-center px-2 ">
                                                {method.typal
                                                    ? 'Типовой'
                                                    : 'Иной'}
                                            </div>
                                            <div className="grid w-28 place-items-center text-center px-2 ">
                                                {method.closed_purchase
                                                    ? 'Закрытый'
                                                    : 'Открытый'}
                                            </div>
                                            <div className="grid w-52 place-items-center text-center px-2">
                                                {getNameTypalKind(
                                                    method.typal_kind
                                                )}
                                            </div>
                                            <div className="grid w-28 place-items-center text-center px-2 ">
                                                {method.competitive
                                                    ? 'Конкурентный'
                                                    : 'Неконкурентный'}
                                            </div>
                                            <div className="grid w-28 place-items-center text-center px-2 ">
                                                {method.is_electronic
                                                    ? 'В электронной форме'
                                                    : 'В бумажной форме'}
                                            </div>
                                            <div className="grid w-28 place-items-center text-center ">
                                                {method.purchase_smb_type
                                                    ? 'Есть'
                                                    : 'Нет'}
                                            </div>
                                            <div className="grid w-28 place-items-center text-center text-[#8A8A8A] py-2">
                                                {method.modification_date
                                                    ?.slice(0, 10)
                                                    .split('-')
                                                    .reverse()
                                                    .join('-')
                                                    .replace(/[\-]/g, '.')}
                                                <br />
                                                {method.modification_date?.slice(
                                                    11,
                                                    16
                                                )}
                                            </div>
                                        </div>
                                        <div className="grid w-10 place-items-center items-center">
                                            <button
                                                className="w-6 h-6 grid rounded-full place-items-center hover:bg-[#701F1F]"
                                                onClick={() =>
                                                    handlePopupDeletePurchaseMethod(
                                                        method.guid
                                                    )
                                                }
                                                title="Удалить"
                                            >
                                                <svg
                                                    className="w-3 h-3"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 24 24"
                                                    id="trash-alt"
                                                >
                                                    <path
                                                        fill="#FFFFFF"
                                                        d="M10,18a1,1,0,0,0,1-1V11a1,1,0,0,0-2,0v6A1,1,0,0,0,10,18ZM20,6H16V5a3,3,0,0,0-3-3H11A3,3,0,0,0,8,5V6H4A1,1,0,0,0,4,8H5V19a3,3,0,0,0,3,3h8a3,3,0,0,0,3-3V8h1a1,1,0,0,0,0-2ZM10,5a1,1,0,0,1,1-1h2a1,1,0,0,1,1,1V6H10Zm7,14a1,1,0,0,1-1,1H8a1,1,0,0,1-1-1V8H17Zm-3-1a1,1,0,0,0,1-1V11a1,1,0,0,0-2,0v6A1,1,0,0,0,14,18Z"
                                                    ></path>
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                ))}
                            </div>

                            {/* buttons */}
                            <div className="flex flex-row gap-1 text-white p-2 bg-[#333033] rounded-b-lg h-10 space-x-2">
                                <button
                                    className="button flex flex-row justify-start items-start"
                                    onClick={() =>
                                        navigate('/hub/org/methods/new/edit')
                                    }
                                >
                                    <svg
                                        className="plus-icon flex h-4 w-4 justify-start items-start mr-1"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 24 24"
                                        id="plus"
                                    >
                                        <path
                                            fill="#FFF"
                                            d="M19,11H13V5a1,1,0,0,0-2,0v6H5a1,1,0,0,0,0,2h6v6a1,1,0,0,0,2,0V13h6a1,1,0,0,0,0-2Z"
                                        ></path>
                                    </svg>{' '}
                                    Создать способ закупки
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {popupDeletePurchaseMethod && (
                <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center">
                    <div
                        className="p-5 rounded-lg bg-[#262427] w-[400px]"
                        onClick={(e) => e.stopPropagation()}
                    >
                        <h2 className="text-white">Удаление способа закупки</h2>
                        <div className="flex flex-col mt-2 space-y-1">
                            <label className="text-[12.8px] text-[#8A8A8A]">
                                Вы точно хотите удалить способ{' '}
                                <span className="text-white">{`"${getNamePurchaseMethod(selectedPurchaseMethodGuid)} ?"`}</span>
                            </label>
                        </div>
                        <div className="flex flex-row mt-5 text-right justify-end">
                            <button
                                className="px-4 py-1 rounded-full shadow-md text-xs text-white bg-[#494849] hover:bg-[#656365]"
                                onClick={() =>
                                    handlePopupDeletePurchaseMethod('')
                                }
                            >
                                Отмена
                            </button>
                            <button
                                className={`flex px-4 py-1 w-24 h-7 rounded-full shadow-md text-xs text-white ml-2 items-center justify-center bg-[#494849] hover:bg-[#701F1F]`}
                                onClick={handleDeletePurchaseMethod} // Вызвать handleAdd
                            >
                                {isDeleting ? (
                                    <svg
                                        className="animate-spin h-4 w-4 text-white"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                    >
                                        <circle
                                            className="opacity-25"
                                            cx="12"
                                            cy="12"
                                            r="10"
                                            stroke="currentColor"
                                            strokeWidth="4"
                                        ></circle>
                                        <path
                                            className="opacity-75"
                                            fill="currentColor"
                                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                                        ></path>
                                    </svg>
                                ) : (
                                    'Удалить'
                                )}
                            </button>
                        </div>
                    </div>
                </div>
            )}
            {popupPurchaseMethodFromEis && (
                <div
                    className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center"
                    onClick={handlePopupPurchaseMethodFromEis}
                >
                    <div
                        className="p-5 rounded-lg bg-[#262427] w-[600px] max-h-[800px]"
                        onClick={(e) => e.stopPropagation()}
                    >
                        <h2 className="text-white">Способы закупок в ЕИС</h2>
                        <div className="flex flex-col mt-2 space-y-1">
                            <label className="text-[12.8px] text-white">
                                Какие способы закупок добавить/обновить?
                            </label>
                            <div className="flex flex-row w-full space-x-5 pt-2">
                                <div className="flex flex-col w-full">
                                    {/* name */}
                                    <div className="flex flex-row gap-1 bg-[#333033] text-[#8A8A8A] text-[10px] px-1 py-2 rounded-t-lg">
                                        <div className="flex w-10 items-center justify-center text-center">
                                            № <br />
                                            П/п
                                        </div>
                                        <div className="flex w-12 items-center justify-center text-center">
                                            Id способа
                                        </div>
                                        <div className="flex flex-1 items-center justify-start text-center">
                                            Название способо
                                        </div>
                                        <div className="flex w-28 items-center justify-center text-center">
                                            Дата создания
                                        </div>
                                        <div className="flex w-10 items-center justify-center"></div>
                                    </div>
                                    {/* data */}
                                    <div className="flex flex-col h-[400px] overflow-y-auto gap-1 py-1 px-1 grow bg-[#1D1C1E] rounded-b-lg">
                                        <div className="flex flex-col w-full space-y-1">
                                            {purchaseMethodFromEis?.map(
                                                (item, index) => (
                                                    <div
                                                        key={item.guid}
                                                        className="flex flex-row w-full rounded bg-[#2A282B] hover:bg-[#373538]"
                                                    >
                                                        <div className="flex flex-row w-full">
                                                            <div className="flex flex-row flex-1 gap-1 text-white text-[11px] py-2 pr-1">
                                                                <div className="flex w-10 items-center justify-center text-[#8A8A8A]">
                                                                    {index + 1}
                                                                </div>
                                                                <div className="flex w-12 items-center justify-center">
                                                                    {item.code
                                                                        ? item.code
                                                                        : '-'}
                                                                </div>
                                                                <div className="flex  flex-1 items-center justify-left p-1">
                                                                    {item.name
                                                                        ? item.name
                                                                        : '-'}
                                                                </div>
                                                                <div className="flex w-24 items-center justify-end">
                                                                    {item.create_date_time
                                                                        ? formatDate(
                                                                              item.create_date_time
                                                                          )
                                                                        : '-'}
                                                                </div>
                                                                <div className="flex w-10 items-center justify-center text-[#8A8A8A] ">
                                                                    <button
                                                                        className="w-6 h-6 grid rounded-full place-items-center hover:bg-[#701F1F]"
                                                                        onClick={() =>
                                                                            handleRemoveFromList(
                                                                                item.guid
                                                                            )
                                                                        }
                                                                        title="Удалить"
                                                                    >
                                                                        <svg
                                                                            className="w-3 h-3"
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            viewBox="0 0 24 24"
                                                                            id="trash-alt"
                                                                        >
                                                                            <path
                                                                                fill="#FFFFFF"
                                                                                d="M10,18a1,1,0,0,0,1-1V11a1,1,0,0,0-2,0v6A1,1,0,0,0,10,18ZM20,6H16V5a3,3,0,0,0-3-3H11A3,3,0,0,0,8,5V6H4A1,1,0,0,0,4,8H5V19a3,3,0,0,0,3,3h8a3,3,0,0,0,3-3V8h1a1,1,0,0,0,0-2ZM10,5a1,1,0,0,1,1-1h2a1,1,0,0,1,1,1V6H10Zm7,14a1,1,0,0,1-1,1H8a1,1,0,0,1-1-1V8H17Zm-3-1a1,1,0,0,0,1-1V11a1,1,0,0,0-2,0v6A1,1,0,0,0,14,18Z"
                                                                            ></path>
                                                                        </svg>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-row mt-5 text-right justify-end">
                            {isAdding ? (
                                ''
                            ) : (
                                <button
                                    className="px-4 py-1 rounded-full shadow-md text-xs text-white bg-[#494849] hover:bg-[#656365]"
                                    onClick={handlePopupPurchaseMethodFromEis}
                                >
                                    Отмена
                                </button>
                            )}
                            <button
                                className={`flex px-4 py-1 w-40 h-7 rounded-full shadow-md text-xs text-white ml-2 items-center justify-center ${isAdding ? 'bg-[#494849] ' : 'bg-green-700 hover:bg-green-600'}`}
                                onClick={handleAddClick} // Вызвать handleCopy
                                disabled={isAdding ? true : false}
                            >
                                {isAdding ? (
                                    <svg
                                        className="animate-spin h-4 w-4 text-white"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                    >
                                        <circle
                                            className="opacity-25"
                                            cx="12"
                                            cy="12"
                                            r="10"
                                            stroke="currentColor"
                                            strokeWidth="4"
                                        ></circle>
                                        <path
                                            className="opacity-75"
                                            fill="currentColor"
                                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                                        ></path>
                                    </svg>
                                ) : (
                                    'Добавить/Обновить'
                                )}
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default OrgPurchaseMethodsPage
