import React, { useState } from 'react'
import Section from '../../../../../../components/Section'
import MoneyInput from '../../../../../../components/form/MoneyInput'
import CurrencySelector from '../../../../../../components/currency/CurrencySelector'
import CurrencyExchangeRateInput from '../../../../../../components/currency/CurrencyExchangeRateInput'
import CurrencyDateSelector from '../../../../../../components/currency/CurrencyDateSelector'

type Props = {
    name: string
    onNameChange: (value: string) => void
    minRequirements: string
    onMinRequirementsChange: (value: string) => void
    contractPriceReason: string
    onContractPriceReasonChange: (value: string) => void
    nmcd: number
    onNmcdChange: (value: number) => void
    isNmcdWithTax: boolean
    onNmcdWithTaxChange: (value: boolean) => void
    currency: string
    onCurrencyChange: (value: string) => void
    currencyExchangeDate?: Date
    onCurrencyExchangeDateChange?: (value: Date) => void
    currencyExchangeRate?: number
    onCurrencyExchangeRateChange?: (value: number) => void
}

const _maybeGetCurrencyConversionInfo = (props: Props) => {
    if (
        props.currency !== 'RUB' &&
        props.currencyExchangeDate &&
        props.onCurrencyExchangeDateChange &&
        props.currencyExchangeRate &&
        props.onCurrencyExchangeRateChange
    ) {
        return (
            <>
                <div className="mt-2 flex flex-col">
                    <CurrencyDateSelector
                        value={props.currencyExchangeDate}
                        onChange={props.onCurrencyExchangeDateChange}
                    />
                </div>
                <div className="mt-2 flex flex-col">
                    <CurrencyExchangeRateInput
                        value={props.currencyExchangeRate}
                        onChange={props.onCurrencyExchangeRateChange}
                    />
                </div>
                <div className="mt-2 flex flex-col">
                    <span className="mb-1 text-sm text-zinc-500">
                        Сведения о НМЦД (в руб.)
                    </span>
                    <MoneyInput
                        value={props.nmcd * props.currencyExchangeRate}
                        disabled={true}
                        className=" form-input bg-[#1F1E1F] border border-[#2A282B] focus:outline-none focus:ring-1 focus:ring-[#FFFFFF]"
                    />
                </div>
            </>
        )
    }
}

const PurchaseBasicInfo: React.FC<Props> = (props) => {
    return (
        <Section title="Информация о предмете договора">
            <div className="flex flex-col">
                <label className="mb-1 text-sm text-zinc-500">
                    Наименование предмета договора
                </label>
                <textarea
                    className="p-2 rounded-md border-0 text-sm bg-[#181818] text-white focus:outline-none focus:ring-1 focus:ring-[#FFFFFF]"
                    maxLength={2000}
                    rows={3}
                    value={props.name}
                    onChange={(e) => props.onNameChange(e.target.value)}
                />

                <label className="mt-2 mb-1 text-sm text-zinc-500">
                    Минимально необходимые требования, предъявляемые к
                    закупаемым товарам, работам, услугам
                </label>
                <textarea
                    className="p-2 rounded-md border-0 text-sm bg-[#181818] text-white focus:outline-none focus:ring-1 focus:ring-[#FFFFFF]"
                    maxLength={2000}
                    rows={3}
                    value={props.minRequirements}
                    onChange={(e) =>
                        props.onMinRequirementsChange(e.target.value)
                    }
                />

                <label className="mt-2 mb-1 text-sm text-zinc-500">
                    Обоснование начальной (максимальной) цены договора
                </label>
                <textarea
                    className="p-2 rounded-md border-0 text-sm bg-[#181818] text-white focus:outline-none focus:ring-1 focus:ring-[#FFFFFF]"
                    maxLength={2000}
                    rows={3}
                    value={props.contractPriceReason}
                    onChange={(e) =>
                        props.onContractPriceReasonChange(e.target.value)
                    }
                />

                <div className="flex flex-row flex-wrap gap-x-5">
                    <div className="mt-2 flex flex-col">
                        <span className="form-label">Сведения о НМЦД</span>
                        <MoneyInput
                            value={props.nmcd}
                            onChange={props.onNmcdChange}
                            className=" form-input focus:outline-none focus:ring-1 focus:ring-[#FFFFFF]"
                        />
                    </div>

                    <div className="mt-2 flex flex-col">
                        <CurrencySelector
                            value={props.currency}
                            onChange={props.onCurrencyChange}
                        />
                    </div>

                    {_maybeGetCurrencyConversionInfo(props)}

                    <div className="mt-2 flex flex-col">
                        <span className="form-label">НМЦД включает НДС</span>
                        <select
                            className="form-select  focus:outline-none focus:ring-1 focus:ring-[#FFFFFF]"
                            value={props.isNmcdWithTax ? 'yes' : 'no'}
                            onChange={(e) =>
                                props.onNmcdWithTaxChange(
                                    e.target.value === 'yes'
                                )
                            }
                        >
                            <option value="yes">Да</option>
                            <option value="no">Нет</option>
                        </select>
                    </div>
                </div>
            </div>
        </Section>
    )
}

export default PurchaseBasicInfo
