import React, { useState, useEffect } from 'react'
import { PlanPositionRow } from '../types'

type LayoutProps = {
    index: number
    positionRow: PlanPositionRow
    showRegion: boolean
    onCountChange: () => void
    onDeleteClick: (index: number) => void
}

const PurchaseItemsTableRow: React.FC<LayoutProps> = ({
    index,
    positionRow,
    showRegion,
    onCountChange,
    onDeleteClick,
}) => {
    return (
        <div className="flex flex-row gap-1 m-1 bg-[#242225] text-[11px] rounded">
            <div className="flex flex-row h-8 w-8 place-items-center text-center">
                <div className="w-6 h-6 grid rounded-full place-items-center bg-[#242225] hover:bg-[#656365]">
                    <input type="checkbox" className="p-2" />
                </div>
            </div>

            <div className="flex flex-row h-8 w-12 place-items-center text-center text-white">
                {index + 1}
            </div>

            <div className="grid flex-1 text-left min-w-72">
                <textarea
                    className="flex h-8 min-h-8 w-full p-2 rounded-md border-0 bg-[#181818] text-white focus:outline-none focus:ring-1 focus:ring-[#FFFFFF]"
                    maxLength={2000}
                    value={positionRow.additional_info ?? ''}
                />
            </div>

            <div className="flex flex-row h-8 w-8 place-items-center text-center">
                <div className="w-6 h-6 grid rounded-full place-items-center bg-[#242225] hover:bg-[#656365]">
                    <input type="checkbox" className="p-2" />
                </div>
            </div>

            <div className="flex flex-row h-8 w-28 place-items-center text-center">
                <input
                    type="number"
                    min="0"
                    name="purchase_item_count"
                    className="h-8 rounded-md text-primary bg-[#181818] border-none text-center w-full pr-1 text-white focus:outline-none focus:ring-1 focus:ring-[#FFFFFF]"
                    style={{ colorScheme: 'dark' }}
                    value={positionRow.qty}
                    onChange={onCountChange}
                />
            </div>

            <div className="flex flex-row h-8 w-32 place-items-center text-center text-white">
                <select
                    name="purchase_item_count_type"
                    className="h-8 px-2 rounded-md bg-[#181818] w-full focus:outline-none focus:ring-1 focus:ring-[#FFFFFF]"
                >
                    <option className="text-[#8A8A8A]">Выберите</option>
                    <option>Штука</option>
                    <option>Условная единица</option>
                    <option>Метр</option>
                </select>
            </div>

            <div className="flex flex-row h-8 w-28 place-items-center text-center text-white">
                <select
                    name="purchase_item_count_type"
                    className="h-8 px-2 rounded-md bg-[#181818] w-full focus:outline-none focus:ring-1 focus:ring-[#FFFFFF]"
                >
                    <option className="text-[#8A8A8A]">Выберите</option>
                    <option>Товар</option>
                    <option>Работа</option>
                    <option>Услуга</option>
                </select>
            </div>

            <div className="flex flex-row h-8 w-28 pl-2 pr-1 place-items-center text-center rounded-md bg-[#181818] text-white focus:outline-none focus:ring-1 focus:ring-[#FFFFFF]">
                <input
                    type="text"
                    className="h-8 w-full rounded-md bg-[#181818]"
                    value={positionRow.okpd2_name ?? ''}
                    disabled
                />
                <div>
                    <button className="w-6 h-6 grid rounded-full place-items-center bg-[#181818] hover:bg-[#656365]">
                        <svg
                            className="w-3 h-3"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            id="bars"
                        >
                            <path
                                fill="#FFFFFF"
                                d="M3,8H21a1,1,0,0,0,0-2H3A1,1,0,0,0,3,8Zm18,8H3a1,1,0,0,0,0,2H21a1,1,0,0,0,0-2Zm0-5H3a1,1,0,0,0,0,2H21a1,1,0,0,0,0-2Z"
                            ></path>
                        </svg>
                    </button>
                </div>
            </div>

            <div className="flex flex-row h-8 w-28 pl-2 pr-1 place-items-center text-center rounded-md bg-[#181818] text-white focus:outline-none focus:ring-1 focus:ring-[#FFFFFF]">
                <input
                    type="text"
                    className="h-8 w-full rounded-md bg-[#181818]"
                    value={positionRow.okved2_name ?? ''}
                    disabled
                />
                <div>
                    <button className="w-6 h-6 grid rounded-full place-items-center bg-[#181818] hover:bg-[#656365]">
                        <svg
                            className="w-3 h-3"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            id="bars"
                        >
                            <path
                                fill="#FFFFFF"
                                d="M3,8H21a1,1,0,0,0,0-2H3A1,1,0,0,0,3,8Zm18,8H3a1,1,0,0,0,0,2H21a1,1,0,0,0,0-2Zm0-5H3a1,1,0,0,0,0,2H21a1,1,0,0,0,0-2Z"
                            ></path>
                        </svg>
                    </button>
                </div>
            </div>

            {showRegion && (
                <div className="flex flex-row h-8 w-44 pl-2 pr-1 place-items-center text-center rounded-md bg-[#181818] text-white focus:outline-none focus:ring-1 focus:ring-[#FFFFFF]">
                    <input
                        type="text"
                        className="h-8 w-full rounded-md bg-[#181818]"
                        value={positionRow.region ?? ''}
                        disabled
                    />
                    <div>
                        <button className="w-6 h-6 grid rounded-full place-items-center bg-[#181818] hover:bg-[#656365]">
                            <svg
                                className="w-3 h-3"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                id="bars"
                            >
                                <path
                                    fill="#FFFFFF"
                                    d="M3,8H21a1,1,0,0,0,0-2H3A1,1,0,0,0,3,8Zm18,8H3a1,1,0,0,0,0,2H21a1,1,0,0,0,0-2Zm0-5H3a1,1,0,0,0,0,2H21a1,1,0,0,0,0-2Z"
                                ></path>
                            </svg>
                        </button>
                    </div>
                </div>
            )}

            <div className="flex flex-row h-8 w-8 place-items-center text-center">
                <button
                    onClick={(e) => onDeleteClick(index)}
                    className="w-6 h-6 grid rounded-full place-items-center hover:bg-[#701F1F]"
                >
                    <svg
                        className="w-3 h-3"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        id="trash-alt"
                    >
                        <path
                            fill="#FFFFFF"
                            d="M10,18a1,1,0,0,0,1-1V11a1,1,0,0,0-2,0v6A1,1,0,0,0,10,18ZM20,6H16V5a3,3,0,0,0-3-3H11A3,3,0,0,0,8,5V6H4A1,1,0,0,0,4,8H5V19a3,3,0,0,0,3,3h8a3,3,0,0,0,3-3V8h1a1,1,0,0,0,0-2ZM10,5a1,1,0,0,1,1-1h2a1,1,0,0,1,1,1V6H10Zm7,14a1,1,0,0,1-1,1H8a1,1,0,0,1-1-1V8H17Zm-3-1a1,1,0,0,0,1-1V11a1,1,0,0,0-2,0v6A1,1,0,0,0,14,18Z"
                        ></path>
                    </svg>
                </button>
            </div>
        </div>
    )
}

export default PurchaseItemsTableRow
