import { jwtDecode } from 'jwt-decode'

export type User = {
    token: string
    object: any
}

const shouldUpdateToken = (): boolean => {
    const refreshTime = localStorage.getItem('refresh_time')
    if (refreshTime == null) {
        return true
    }
    // more than a day
    return Date.now() - Number(refreshTime) > 86_400_000
}

export const getUserData = async (): Promise<User | null> => {
    console.log('checking token...')

    return fetch('https://itzakupki.ru/api/auth/v1/check_jwt/', {
        method: 'GET',
        credentials: 'include',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
    })
        .then((r) => {
            console.log('token check result: ' + r.ok)

            if (r.ok && shouldUpdateToken()) {
                console.log('updating token...')

                return fetch(
                    'https://itzakupki.ru/api/auth/v1/users/token/refresh/',
                    {
                        method: 'POST',
                        credentials: 'include',
                        headers: {
                            Accept: 'application/json',
                            'Content-Type': 'application/json',
                        },
                    }
                )
                    .then((resp) => {
                        if (resp.ok) {
                            return resp.json()
                        } else {
                            throw new Error('Cannot update token')
                        }
                    })
                    .then((resp) => {
                        console.log('token updated')
                        localStorage.setItem(
                            'refresh_time',
                            Date.now().toString()
                        )
                        localStorage.setItem('access_token', resp.access_token)
                        return true
                    })
            }

            return r.ok
        })
        .then((result) => {
            const accessToken = result
                ? localStorage.getItem('access_token')
                : null
            if (accessToken == null) {
                return null
            }
            return {
                token: accessToken,
                object: jwtDecode(accessToken),
            }
        })
        .catch(() => null)
}
