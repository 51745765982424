import React from 'react'

const OrgRolesPage: React.FC = () => {
    return (
        <div className="flex flex-col text-white h-full">
            <div className="bg-[#111111]">
                <div className="w-auto h-20 mx-6 flex flex-row gap-x-5 ">
                    <div className="w-60 h-20 my-auto content-center ">
                        <p className="text-sm text-white">Реестр ролей</p>
                    </div>
                </div>
            </div>

            <div className="flex flex-1 justify-center max-h-[calc(100vh_-_80px)] p-5">
                <div className="flex flex-col h-full w-[800px] space-y-10 bg-slate-600">
                    {/* // body */}
                    <div className="flex bg-[#181818] h-[calc(100vh_-_120px)]">
                        {/* table */}
                        <div className="flex flex-col w-full min-w-fit rounded">
                            {/* toolbar */}
                            <div className="flex flex-row gap-1 text-white p-2 bg-[#333033] rounded-t-lg">
                                <div className="w-6 h-6"></div>
                            </div>

                            {/* name */}
                            <div className="flex flex-row gap-1 bg-[#2A282B] text-[#8A8A8A] text-[10px] px-1 py-2">
                                <div className="flex w-10 items-center justify-center text-center">
                                    №
                                </div>
                                <div className="flex flex-1 items-center justify-center text-center">
                                    Наименование роли
                                </div>
                            </div>

                            {/* data */}
                            <div className="flex flex-col overflow-y-auto gap-1 py-1 bg-[#1D1C1E] h-dvh">
                                {/* Вывод структуры данных */}
                                <>
                                    <div className="flex flex-row gap-1 mx-1 bg-[#2A282B] text-white text-[11px] rounded hover:bg-[#373538]">
                                        <div className="flex py-2 w-10 items-center justify-center text-center text-[#8A8A8A]">
                                            1
                                        </div>
                                        <div className="flex py-2 flex-1 items-center justify-start text-left">
                                            Администратор
                                        </div>
                                    </div>

                                    <div className="flex flex-row gap-1 mx-1 bg-[#2A282B] text-white text-[11px] rounded hover:bg-[#373538]">
                                        <div className="flex py-2 w-10 items-center justify-center text-center text-[#8A8A8A]">
                                            2
                                        </div>
                                        <div className="flex py-2 flex-1 items-center justify-start text-left">
                                            Специалист по закупкам
                                        </div>
                                    </div>

                                    <div className="flex flex-row gap-1 mx-1 bg-[#2A282B] text-white text-[11px] rounded hover:bg-[#373538]">
                                        <div className="flex py-2 w-10 items-center justify-center text-center text-[#8A8A8A]">
                                            3
                                        </div>
                                        <div className="flex py-2 flex-1 items-center justify-start text-left">
                                            Инициатор закупки
                                        </div>
                                    </div>

                                    <div className="flex flex-row gap-1 mx-1 bg-[#2A282B] text-white text-[11px] rounded hover:bg-[#373538]">
                                        <div className="flex py-2 w-10 items-center justify-center text-center text-[#8A8A8A]">
                                            4
                                        </div>
                                        <div className="flex py-2 flex-1 items-center justify-start text-left">
                                            Куратор закупок
                                        </div>
                                    </div>

                                    <div className="flex flex-row gap-1 mx-1 bg-[#2A282B] text-white text-[11px] rounded hover:bg-[#373538]">
                                        <div className="flex py-2 w-10 items-center justify-center text-center text-[#8A8A8A]">
                                            5
                                        </div>
                                        <div className="flex py-2 flex-1 items-center justify-start text-left">
                                            Гость
                                        </div>
                                    </div>
                                </>
                            </div>

                            {/* buttons */}
                            <div className="flex flex-row gap-1 text-white p-2 bg-[#333033] rounded-b-lg h-10 space-x-2"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OrgRolesPage
