import React from 'react'
import './index.css'
import { PlanPosition } from '../../types'

type Props = {
    keyindex: number
    planPosition: PlanPosition
    selected: boolean
    onClick: (pos_guid: string) => void
    onDoubleClick: (pos_guid: string) => void
}

const MONTHS = [
    'январь',
    'февраль',
    'март',
    'апрель',
    'май',
    'июнь',
    'июль',
    'август',
    'сентябрь',
    'октябрь',
    'ноябрь',
    'декабрь',
]

const CURRENCY = new Map([
    ['643', '₽'],
    ['156', '¥'],
    ['978', '€'],
    ['840', '$'],
])

const GWS = new Map([
    ['G', 'Товар'],
    ['W', 'Работа'],
    ['S', 'Услуга'],
])

const POSITION_STATUS = new Map([
    ['P', 'Размещена'],
    ['A', 'Аннулирована'],
    ['N', 'Новая'],
    ['C', 'Изменение'],
])

const PlanPositionsTableRow: React.FC<Props> = ({
    keyindex,
    planPosition,
    selected,
    onClick,
    onDoubleClick,
}) => {
    return (
        <div
            className={
                selected
                    ? 'plan-position-table-row-selected'
                    : 'plan-position-table-row'
            }
            onDoubleClick={() => onDoubleClick(planPosition.guid)}
            onClick={() => onClick(planPosition.guid)}
        >
            <div className="flex h-[70px] w-8 items-center justify-center text-center">
                <input
                    type="checkbox"
                    checked={selected}
                    onClick={(e) => e.stopPropagation()}
                    onChange={() => {
                        onClick(planPosition.guid)
                    }}
                />
            </div>
            <div className="flex h-[70px] w-12 items-center justify-center text-center text-[#8A8A8A]">
                {keyindex}
            </div>
            <div className="flex h-[70px] w-12 items-center justify-center text-center text-[#8A8A8A]">
                {planPosition.ordinal_number}
            </div>
            <div className="flex h-[70px] w-24 items-center justify-center text-center bg-[#A63636] rounded">
                {planPosition.position_stage}
            </div>
            <div className="flex h-[70px] w-24 items-center justify-center text-center bg-[#A63636] rounded">
                {planPosition.position_status}
            </div>
            <div
                className="flex h-[70px] flex-1 items-center justify-start text-left min-w-44 px-2 "
                title={planPosition.contract_subject}
            >
                <p className="fourrow">
                    {planPosition.contract_subject
                        ? planPosition.contract_subject
                        : '-'}
                </p>
            </div>
            <div className="flex h-[70px] w-28 items-center justify-end text-right px-2 ">
                {Number(planPosition.maximum_contract_price).toLocaleString(
                    'ru-RU',
                    {
                        minimumFractionDigits: 2,
                    }
                )}{' '}
                {planPosition.currency_digital_code
                    ? CURRENCY.get(String(planPosition.currency_digital_code))
                    : ''}
            </div>
            <div className="flex h-[70px] w-24 items-center justify-end text-right px-2 ">
                {planPosition.purchase_planned_date === null ? (
                    planPosition.purchase_period_month ? (
                        <>
                            {MONTHS[
                                Number(planPosition.purchase_period_month) - 1
                            ] + ' '}
                            {planPosition.purchase_period_year}
                        </>
                    ) : (
                        '-'
                    )
                ) : (
                    planPosition.purchase_planned_date
                        .split('-')
                        .reverse()
                        .join('-')
                        .replace(/[\-]/g, '.')
                )}
            </div>
            <div className="flex h-[70px] w-24 items-center justify-end text-right px-2 ">
                {planPosition.contract_end_date ? (
                    <>
                        {MONTHS[
                            Number(
                                planPosition.contract_end_date?.slice(5, 7)
                            ) - 1
                        ] + ' '}
                        {planPosition.contract_end_date?.slice(0, 4)}
                    </>
                ) : (
                    '-'
                )}
            </div>
            <div className="flex h-[70px] w-44 items-center justify-center text-center">
                <p className="fourrow">
                    {planPosition.purchase_method_name
                        ? planPosition.purchase_method_name
                        : '-'}
                </p>
            </div>
            <div
                className={
                    planPosition.status === 'P'
                        ? 'flex h-[70px] w-24 items-center justify-center text-center bg-[#2F8A43] rounded'
                        : 'flex h-[70px] w-24 items-center justify-center text-center bg-[#A63636] rounded'
                }
            >
                {POSITION_STATUS.get(String(planPosition.status))}
            </div>
            <div className="flex h-[70px] w-20 items-center justify-center text-center">
                {planPosition.type_object_purchase_position
                    ? GWS.get(
                          String(planPosition.type_object_purchase_position)
                      )
                    : '-'}
            </div>
            <div className="flex h-[70px] w-20 items-center justify-center text-center">
                {planPosition.struct_name !== undefined &&
                planPosition.struct_name !== null &&
                planPosition.struct_name !== ''
                    ? `${planPosition.struct_name}`
                    : '-'}
            </div>
            <div className="flex h-[70px] w-20 items-center justify-center text-center">
                {planPosition.expense_items_name !== undefined &&
                planPosition.expense_items_name !== null &&
                planPosition.expense_items_name !== ''
                    ? `${planPosition.expense_items_name}`
                    : '-'}
            </div>
            <div className="flex h-[70px] w-28 items-center justify-center text-center text-[#8A8A8A]">
                Тут будет
                <br />
                "Номер в системе"
            </div>
            <div className="flex h-[70px] w-20 items-center justify-center text-center text-[#8A8A8A]">
                {planPosition.sys_updated_at ? '+' : '-'}
                {planPosition.sys_updated_at
                    ?.slice(0, 10)
                    .split('-')
                    .reverse()
                    .join('-')
                    .replace(/[\-]/g, '.')}
                <br />
                {planPosition.sys_updated_at?.slice(11, 16)}
            </div>
            {/* <div className="flex h-[70px] w-12 items-center justify-center text-center">
    <div className="text-xs text-white bg-[#3a3a3a] w-7 h-7 rounded-full mx-auto my-auto content-center  ">
        AA
    </div>
</div> */}
        </div>
    )
}

export default PlanPositionsTableRow
