import React, { useState } from 'react'
import { useLoaderData } from 'react-router-dom'
import { Structure } from './types'
import './style.css'

export const loadStructure = async (): Promise<Structure> => {
    return fetch('https://itzakupki.ru/api/backend/v1/organization_struct', {
        method: 'GET',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
        },
    })
        .then((response) => {
            if (response.ok) {
                return response.json()
            } else {
                throw new Error('Failed to fetch organization')
            }
        })
        .catch((e) => {
            console.error(e)
        })
}

const OrgStructurePage: React.FC = () => {
    const structureLoad = useLoaderData() as Structure[]
    const [structureData, setStructureData] =
        useState<Structure[]>(structureLoad)
    const [structureUpdate, setStructureUpdate] = useState<Structure | null>(
        null
    )
    const [selectParentStructure, setSelectParentStructure] =
        useState<Structure[]>(structureLoad)
    const [popupAddStructure, setPopupAddStructure] = useState(false)
    const [popupDeleteStructure, setPopupDeleteStructure] = useState(false)
    const [isAdding, setIsAdding] = useState(false) // Состояние для анимации загрузки
    const [isDeleting, setIsDeleting] = useState(false) // Состояние для анимации загрузки
    const [touchedInput, setTouchedInput] = useState<{
        [key: string]: boolean
    }>({})
    const [errorsInput, setErrorsInput] = useState<{ [key: string]: string }>(
        {}
    )
    const [selectedSctructureGuid, setSelectedSctructureGuid] = useState('')
    const [isFiltering, setIsFiltering] = useState(false)
    const [filters, setFilters] = useState({
        structName: '',
        structShortName: '',
        parentStruct: '',
    })

    const handlerFilter = () => {
        setIsFiltering(!isFiltering)

        setFilters({
            structName: '',
            structShortName: '',
            parentStruct: '',
        })
    }

    const handlerFilterClear = () => {
        setFilters({
            structName: '',
            structShortName: '',
            parentStruct: '',
        })
    }

    // Функция фильтрации данных
    const getFilteredData = () => {
        return structureData.filter((struct) => {
            const nameMatches = struct.struct_name
                .toLowerCase()
                .includes(filters.structName.toLowerCase())
            const shortNameMatches = struct.struct_short_name
                .toLowerCase()
                .includes(filters.structShortName.toLowerCase())
            const parentMatches =
                filters.parentStruct === '' ||
                struct.parent_struct_guid === filters.parentStruct ||
                (filters.parentStruct === '-' && !struct.parent_struct_guid)
            return nameMatches && shortNameMatches && parentMatches
        })
    }

    const getNameParentStruct = (struct_guid: string) => {
        const nameSctruct = structureData.find((s) => s.guid === struct_guid)
        return nameSctruct?.struct_short_name || '-'
    }

    const listParentStruct = () => {
        // Собираем все уникальные parent_struct_guid
        const parentGuids = new Set(
            structureData.map((s) => s.parent_struct_guid).filter(Boolean)
        )
        // Находим объекты, соответствующие этим guid
        const listStruct = structureData.filter((s) => parentGuids.has(s.guid))
        // Возвращаем только необходимые поля
        return listStruct.map((s) => ({
            guid: s.guid,
            struct_short_name: s.struct_short_name,
        }))
    }

    // Валидация полей
    const validateField = (fieldName: keyof Structure, value: string) => {
        if (['struct_name', 'struct_short_name'].includes(fieldName)) {
            let error = ''

            if (!value) {
                error = 'Поле обязательно к заполнению'
            }
            setErrorsInput((prev) => ({ ...prev, [fieldName]: error }))
        }
    }

    // Обработчик для изменения поля
    const handleInputChange = (
        fieldName: keyof Structure,
        value: string | null
    ) => {
        setStructureUpdate(
            (prev) =>
                ({
                    ...prev,
                    [fieldName]: value,
                }) as Structure
        )
    }

    const handleBlur = (fieldName: keyof Structure) => {
        setTouchedInput((prev) => ({ ...prev, [fieldName]: true }))
        if (structureUpdate) {
            validateField(
                fieldName,
                structureUpdate[fieldName].toString() || ''
            )
        }
    }

    const getBorderStyle = (fieldName: keyof Structure) => {
        const isTouched = touchedInput[fieldName]
        const hasError = errorsInput[fieldName]
        return isTouched && hasError ? '1px solid #C15C5C' : ''
    }

    // Проверяем, есть ли ошибки
    const hasErrors = () => {
        return Object.values(errorsInput).some((error) => error !== '')
    }

    const handleClickStructure = (struct_guid: string) => {
        setPopupAddStructure(!popupAddStructure)
        setSelectedSctructureGuid(struct_guid)

        const foundStruct = structureData.find((s) => s.guid === struct_guid)
        setStructureUpdate(foundStruct || null)

        const selectStruct = structureData.filter((s) => s.guid !== struct_guid)

        selectStruct
            ? setSelectParentStructure(selectStruct)
            : setSelectParentStructure(structureData)
    }

    const handlePopupAddStructure = () => {
        setPopupAddStructure(!popupAddStructure)
        setErrorsInput({})
        setSelectedSctructureGuid('')
        setSelectParentStructure(structureData)
        setStructureUpdate(null)
    }

    const handleAddBankDetails = async () => {
        setIsAdding(true) // Включаем анимацию загрузки
        if (selectedSctructureGuid !== '') {
            try {
                const response = await fetch(
                    `https://itzakupki.ru/api/backend/v1/organization_struct/${selectedSctructureGuid}`,
                    {
                        method: 'PUT',
                        credentials: 'include',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(structureUpdate), // Добавлено тело запроса
                    }
                )
                if (!response.ok) {
                    throw new Error('Ошибка при изменении структуры')
                }

                console.log('Струткура успешно изменена')
                setPopupAddStructure(false)
            } catch (error) {
                console.error('Ошибка при изменении структуры:', error)
            } finally {
                setIsAdding(false) // Отключаем анимацию загрузки
            }
        } else {
            try {
                const response = await fetch(
                    `https://itzakupki.ru/api/backend/v1/organization_struct`,
                    {
                        method: 'POST',
                        credentials: 'include',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(structureUpdate), // Добавлено тело запроса
                    }
                )
                if (!response.ok) {
                    throw new Error('Ошибка при добавлении структуры')
                }

                console.log('Струткура успешно добавлена')
                setPopupAddStructure(false)
            } catch (error) {
                console.error('Ошибка при добавлении структуры:', error)
            } finally {
                setIsAdding(false) // Отключаем анимацию загрузки
            }
        }
        const data = (await loadStructure()) as unknown as Structure[]
        setStructureData(data)
    }

    const handlePopupDeleteStructure = (struct_guid: string) => {
        setPopupDeleteStructure(!popupDeleteStructure)
        setErrorsInput({})
        setSelectedSctructureGuid(struct_guid)
    }

    const handleDeleteStructure = async () => {
        setIsDeleting(true) // Включаем анимацию загрузки

        if (!selectedSctructureGuid) return

        try {
            const response = await fetch(
                `https://itzakupki.ru/api/backend/v1/organization_struct/${selectedSctructureGuid}`,
                {
                    method: 'DELETE',
                    credentials: 'include',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            )
            if (!response.ok) {
                throw new Error('Ошибка при удалении')
            }
            setPopupDeleteStructure(false)
            setStructureData((prevStruct) =>
                prevStruct.filter(
                    (struct) => struct.guid !== selectedSctructureGuid
                )
            )
            setSelectedSctructureGuid('')
            console.log('Структура успешно удалена')
        } catch (error) {
            console.error('Ошибка удаления струткры:', error)
        } finally {
            setIsDeleting(false) // Отключаем анимацию загрузки
        }
    }

    return (
        <div className="flex flex-col text-white h-full">
            <div className="bg-[#111111]">
                <div className="w-auto h-20 mx-6 flex flex-row gap-x-5 ">
                    <div className="w-60 h-20 my-auto content-center ">
                        <p className="text-sm text-white">
                            Структура организации
                        </p>
                    </div>
                </div>
            </div>

            <div className="flex flex-1 justify-center max-h-[calc(100vh_-_80px)] p-5">
                <div className="flex flex-col h-full w-[800px] space-y-10 bg-slate-600">
                    {/* // body */}
                    <div className="flex bg-[#181818] h-[calc(100vh_-_120px)]">
                        {/* table */}
                        <div className="flex flex-col w-full min-w-fit">
                            {/* toolbar */}
                            <div className="flex flex-row gap-1 text-white p-2 bg-[#333033] rounded-t-lg">
                                {/* Add */}
                                <button
                                    className="w-6 h-6 grid rounded-full place-items-center hover:bg-[#656365]"
                                    onClick={handlePopupAddStructure}
                                    title="Добавить структуру"
                                >
                                    <svg
                                        className="w-3 h-3"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 24 24"
                                        id="plus"
                                    >
                                        <path
                                            fill="#FFFFFF"
                                            d="M19,11H13V5a1,1,0,0,0-2,0v6H5a1,1,0,0,0,0,2h6v6a1,1,0,0,0,2,0V13h6a1,1,0,0,0,0-2Z"
                                        ></path>
                                    </svg>
                                </button>

                                {/* Filter */}
                                <button
                                    className="w-6 h-6 grid rounded-full place-items-center hover:bg-[#656365]"
                                    onClick={handlerFilter}
                                    title={
                                        isFiltering
                                            ? 'Убрать фильтр'
                                            : 'Показать фильтр'
                                    }
                                >
                                    {isFiltering ? (
                                        <svg
                                            className="w-3 h-3"
                                            xmlns="http://www.w3.org/2000/svg"
                                            data-name="Layer 1"
                                            viewBox="0 0 24 24"
                                            id="filter-slash"
                                        >
                                            <path
                                                id="svg_1"
                                                d="m19,2l-14,0a3,3 0 0 0 -3,3l0,1.17a3,3 0 0 0 0.25,1.2l0,0.06a2.81,2.81 0 0 0 0.59,0.86l6.16,6.12l0,6.59a1,1 0 0 0 0.47,0.85a1,1 0 0 0 0.53,0.15a1,1 0 0 0 0.45,-0.11l4,-2a1,1 0 0 0 0.55,-0.89l0,-4.59l6.12,-6.12a2.81,2.81 0 0 0 0.59,-0.86l0,-0.06a3,3 0 0 0 0.29,-1.2l0,-1.17a3,3 0 0 0 -3,-3zm-5.71,11.29a1,1 0 0 0 -0.29,0.71l0,4.38l-2,1l0,-5.38a1,1 0 0 0 -0.29,-0.71l-5.3,-5.29l13.18,0l-5.3,5.29zm6.71,-7.29l-16,0l0,-1a1,1 0 0 1 1,-1l14,0a1,1 0 0 1 1,1l0,1z"
                                                fill="#FFFFFF"
                                            />
                                            <path
                                                id="svg_2"
                                                d="m10.35653,-2.20952c0,-0.54338 0.45662,-1 1,-1l0.14286,0c0.54338,0 1,0.45662 1,1l0,25.07412c0,0.54338 -0.45662,1 -1,1l-0.14286,0c-0.54338,0 -1,-0.45662 -1,-1l0,-25.07412z"
                                                transform="rotate(-43.639, 11.428, 10.3275)"
                                                opacity="undefined"
                                                fill="#ff0000"
                                            />
                                        </svg>
                                    ) : (
                                        <svg
                                            className="w-3 h-3"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 24 24"
                                            id="filter"
                                        >
                                            <path
                                                fill="#FFFFFF"
                                                d="M19,2H5A3,3,0,0,0,2,5V6.17a3,3,0,0,0,.25,1.2l0,.06a2.81,2.81,0,0,0,.59.86L9,14.41V21a1,1,0,0,0,.47.85A1,1,0,0,0,10,22a1,1,0,0,0,.45-.11l4-2A1,1,0,0,0,15,19V14.41l6.12-6.12a2.81,2.81,0,0,0,.59-.86l0-.06A3,3,0,0,0,22,6.17V5A3,3,0,0,0,19,2ZM13.29,13.29A1,1,0,0,0,13,14v4.38l-2,1V14a1,1,0,0,0-.29-.71L5.41,8H18.59ZM20,6H4V5A1,1,0,0,1,5,4H19a1,1,0,0,1,1,1Z"
                                            ></path>
                                        </svg>
                                    )}
                                </button>
                            </div>

                            {/* name */}
                            <div className="flex flex-row gap-1 bg-[#2A282B] text-[#8A8A8A] text-[10px] px-1 py-2">
                                <div className="flex w-10 items-center justify-center text-center">
                                    №
                                </div>
                                <div className="flex flex-1 items-center justify-center text-center">
                                    Полное наименование структуры
                                </div>
                                <div className="flex w-44 items-center justify-center text-center">
                                    Сокращенное наименование
                                </div>
                                <div className="flex w-36 items-center justify-center text-center">
                                    Родительская структура
                                </div>
                                <div className="flex w-10 items-center justify-center text-center"></div>
                            </div>

                            {/* filter */}
                            {isFiltering ? (
                                <div className="flex flex-row gap-1 bg-[#2A282B] text-[#8A8A8A] text-[10px] px-1">
                                    <div className="flex w-10 items-center justify-center text-center"></div>
                                    <div className="flex flex-1 items-center justify-left text-center">
                                        <input
                                            className="struct-input-placeholder w-full px-2 py-1 rounded-md border-0 text-[11px] bg-[#181818] text-white focus:outline-none focus:ring-1 focus:ring-[#FFFFFF]"
                                            placeholder="Поиск"
                                            value={filters.structName}
                                            onChange={(e) =>
                                                setFilters({
                                                    ...filters,
                                                    structName: e.target.value,
                                                })
                                            }
                                        />
                                    </div>
                                    <div className="flex w-44 items-center justify-center text-center">
                                        <input
                                            className="struct-input-placeholder w-full px-2 py-1 rounded-md border-0 text-[11px] bg-[#181818] text-white focus:outline-none focus:ring-1 focus:ring-[#FFFFFF]"
                                            placeholder="Поиск"
                                            value={filters.structShortName}
                                            onChange={(e) =>
                                                setFilters({
                                                    ...filters,
                                                    structShortName:
                                                        e.target.value,
                                                })
                                            }
                                        />
                                    </div>
                                    <div className="flex w-36 items-center justify-center text-center">
                                        <select
                                            className="w-full px-2 py-1 rounded-md border-0 text-[11px] bg-[#181818] text-white focus:outline-none focus:ring-1 focus:ring-[#FFFFFF]"
                                            value={filters.parentStruct}
                                            onChange={(e) =>
                                                setFilters({
                                                    ...filters,
                                                    parentStruct:
                                                        e.target.value,
                                                })
                                            }
                                        >
                                            <option
                                                value={''}
                                                className="text-[#8a8a8a]"
                                            >
                                                Выбрать
                                            </option>
                                            <option value="-">-</option>
                                            {listParentStruct().map(
                                                (parent) => (
                                                    <option
                                                        key={parent.guid}
                                                        value={parent.guid}
                                                    >
                                                        {
                                                            parent.struct_short_name
                                                        }
                                                    </option>
                                                )
                                            )}
                                        </select>
                                    </div>
                                    <div className="flex w-10 h-8 items-center justify-center text-center">
                                        <button
                                            className="w-6 h-6 grid rounded-full place-items-center hover:bg-[#656365]"
                                            onClick={handlerFilterClear}
                                            title="Очистить фильтр"
                                        >
                                            <svg
                                                className="w-3 h-3"
                                                xmlns="http://www.w3.org/2000/svg"
                                                data-name="Layer 1"
                                                viewBox="0 0 24 24"
                                                id="x"
                                            >
                                                <path
                                                    fill="#FFFFFF"
                                                    d="M8 19a3 3 0 0 1-3-3V8a3 3 0 0 1 3-3 1 1 0 0 0 0-2 5 5 0 0 0-5 5v8a5 5 0 0 0 5 5 1 1 0 0 0 0-2Zm7.71-3.29a1 1 0 0 0 0-1.42L13.41 12l2.3-2.29a1 1 0 0 0-1.42-1.42L12 10.59l-2.29-2.3a1 1 0 0 0-1.42 1.42l2.3 2.29-2.3 2.29a1 1 0 0 0 0 1.42 1 1 0 0 0 1.42 0l2.29-2.3 2.29 2.3a1 1 0 0 0 1.42 0ZM16 3a1 1 0 0 0 0 2 3 3 0 0 1 3 3v8a3 3 0 0 1-3 3 1 1 0 0 0 0 2 5 5 0 0 0 5-5V8a5 5 0 0 0-5-5Z"
                                                ></path>
                                            </svg>
                                        </button>
                                    </div>
                                </div>
                            ) : (
                                ''
                            )}

                            {/* data */}
                            <div className="flex flex-col overflow-y-auto gap-1 p-1 bg-[#1D1C1E] h-dvh">
                                {/* Вывод структуры данных */}
                                <div className="flex flex-col w-full text-white text-[11px] space-y-1">
                                    {getFilteredData().map((struct, index) => (
                                        <div
                                            className="flex flex-row cursor-pointer bg-[#2A282B] hover:bg-[#373538] rounded"
                                            key={struct.guid}
                                        >
                                            <div
                                                className="flex flex-row flex-1 gap-1 py-2"
                                                onClick={() =>
                                                    handleClickStructure(
                                                        struct.guid
                                                    )
                                                }
                                            >
                                                <div className="flex w-10 items-center justify-center text-[#8A8A8A]">
                                                    {index + 1}
                                                </div>
                                                <div className="flex flex-1 items-center justify-start text-left">
                                                    {struct.struct_name}
                                                </div>
                                                <div className="flex w-44 items-center justify-center">
                                                    {struct.struct_short_name}
                                                </div>
                                                <div className="flex w-36 items-center justify-center text-[#8A8A8A]">
                                                    {getNameParentStruct(
                                                        struct.parent_struct_guid
                                                    )}
                                                </div>
                                            </div>
                                            <div className="flex w-10 items-center justify-center ml-1">
                                                <button
                                                    className="w-6 h-6 grid rounded-full place-items-center hover:bg-[#701F1F]"
                                                    onClick={() =>
                                                        handlePopupDeleteStructure(
                                                            struct.guid
                                                        )
                                                    }
                                                    title="Удалить"
                                                >
                                                    <svg
                                                        className="w-3 h-3"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        viewBox="0 0 24 24"
                                                        id="trash-alt"
                                                    >
                                                        <path
                                                            fill="#FFFFFF"
                                                            d="M10,18a1,1,0,0,0,1-1V11a1,1,0,0,0-2,0v6A1,1,0,0,0,10,18ZM20,6H16V5a3,3,0,0,0-3-3H11A3,3,0,0,0,8,5V6H4A1,1,0,0,0,4,8H5V19a3,3,0,0,0,3,3h8a3,3,0,0,0,3-3V8h1a1,1,0,0,0,0-2ZM10,5a1,1,0,0,1,1-1h2a1,1,0,0,1,1,1V6H10Zm7,14a1,1,0,0,1-1,1H8a1,1,0,0,1-1-1V8H17Zm-3-1a1,1,0,0,0,1-1V11a1,1,0,0,0-2,0v6A1,1,0,0,0,14,18Z"
                                                        ></path>
                                                    </svg>
                                                </button>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>

                            {/* buttons */}
                            <div className="flex flex-row gap-1 text-white p-2 bg-[#333033] rounded-b-lg h-10 space-x-2">
                                <button
                                    className="button flex flex-row justify-start items-start"
                                    onClick={handlePopupAddStructure}
                                >
                                    <svg
                                        className="plus-icon flex h-4 w-4 justify-start items-start mr-1"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 24 24"
                                        id="plus"
                                    >
                                        <path
                                            fill="#FFF"
                                            d="M19,11H13V5a1,1,0,0,0-2,0v6H5a1,1,0,0,0,0,2h6v6a1,1,0,0,0,2,0V13h6a1,1,0,0,0,0-2Z"
                                        ></path>
                                    </svg>{' '}
                                    Добавить структуру
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {popupAddStructure && (
                <div
                    className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center"
                    onClick={handlePopupAddStructure}
                >
                    <div
                        className="p-5 rounded-lg bg-[#262427] w-[600px]"
                        onClick={(e) => e.stopPropagation()}
                    >
                        <h2>
                            {selectedSctructureGuid ? 'Изменить' : 'Добавить'}{' '}
                            структуру
                        </h2>
                        <div className="flex flex-col mt-5 space-y-1">
                            <label className="text-[12.8px] text-[#8A8A8A]">
                                Полное наименование{' '}
                                <span className="text-[#FF0000]">*</span>
                            </label>
                            <input
                                className="struct-input"
                                style={{
                                    colorScheme: 'dark',
                                    border: getBorderStyle('struct_name'),
                                }}
                                value={structureUpdate?.struct_name ?? ''} // Привязка значения
                                onChange={(e) =>
                                    handleInputChange(
                                        'struct_name',
                                        e.target.value
                                    )
                                }
                                onBlur={() => handleBlur('struct_name')}
                            />
                            {touchedInput.struct_name &&
                                errorsInput.struct_name && (
                                    <span
                                        style={{
                                            color: '#C15C5C',
                                            fontSize: '12.8px',
                                        }}
                                    >
                                        {errorsInput.struct_name}
                                    </span>
                                )}
                        </div>
                        <div className="flex flex-col mt-2 space-y-1">
                            <label className="text-[12.8px] text-[#8A8A8A]">
                                Сокращенное наименование{' '}
                                <span className="text-[#FF0000]">*</span>
                            </label>
                            <input
                                className="struct-input"
                                style={{
                                    colorScheme: 'dark',
                                    border: getBorderStyle('struct_short_name'),
                                }}
                                value={structureUpdate?.struct_short_name ?? ''} // Привязка значения
                                onChange={(e) =>
                                    handleInputChange(
                                        'struct_short_name',
                                        e.target.value
                                    )
                                }
                                onBlur={() => handleBlur('struct_short_name')}
                            />
                            {touchedInput.struct_short_name &&
                                errorsInput.struct_short_name && (
                                    <span
                                        style={{
                                            color: '#C15C5C',
                                            fontSize: '12.8px',
                                        }}
                                    >
                                        {errorsInput.struct_short_name}
                                    </span>
                                )}
                        </div>
                        <div className="flex flex-col w-full space-y-1">
                            <div>
                                <label className="text-[12.8px] text-[#8A8A8A]">
                                    Родительская структура
                                </label>
                            </div>
                            <div>
                                <select
                                    className="struct-input"
                                    value={
                                        structureUpdate?.parent_struct_guid
                                            ? structureUpdate?.parent_struct_guid
                                            : '0'
                                    } // Устанавливаем выбранное значение
                                    onChange={(e) =>
                                        handleInputChange(
                                            'parent_struct_guid',
                                            e.target.value == '0'
                                                ? null
                                                : e.target.value
                                        )
                                    }
                                >
                                    <option value="0">Выбрать</option>
                                    {selectParentStructure.map(
                                        (struct, index) => (
                                            <option
                                                key={index}
                                                value={struct.guid}
                                            >
                                                {struct.struct_name}
                                            </option>
                                        )
                                    )}
                                </select>
                            </div>
                        </div>
                        <div className="flex flex-row items-center justify-end mt-5 text-right space-x-2">
                            <button
                                className="flex h-7 w-[120px] rounded-full text-xs text-white bg-[#494849] hover:bg-[#656365] items-center justify-center"
                                onClick={handlePopupAddStructure}
                            >
                                Отмена
                            </button>
                            <button
                                className={`flex h-7 ${hasErrors() ? 'bg-[#3D3B3F] text-[#8A8A8A]' : !structureUpdate ? 'bg-[#3D3B3F] text-[#8A8A8A]' : 'bg-[#187A33] hover:bg-green-600'} w-[120px] rounded-full text-[12.8px] justify-center items-center`}
                                onClick={handleAddBankDetails}
                                disabled={!structureUpdate ? true : hasErrors()}
                            >
                                {isAdding ? (
                                    <svg
                                        className="animate-spin h-4 w-4 text-white"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                    >
                                        <circle
                                            className="opacity-25"
                                            cx="12"
                                            cy="12"
                                            r="10"
                                            stroke="currentColor"
                                            strokeWidth="4"
                                        ></circle>
                                        <path
                                            className="opacity-75"
                                            fill="currentColor"
                                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                                        ></path>
                                    </svg>
                                ) : selectedSctructureGuid ? (
                                    'Изменить'
                                ) : (
                                    'Добавить'
                                )}
                            </button>
                        </div>
                    </div>
                </div>
            )}

            {popupDeleteStructure && (
                <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center">
                    <div
                        className="p-5 rounded-lg bg-[#262427] w-[400px]"
                        onClick={(e) => e.stopPropagation()}
                    >
                        <h2 className="text-white">Удаление структуры</h2>
                        <div className="flex flex-col mt-2 space-y-1">
                            <label className="text-[12.8px] text-[#8A8A8A]">
                                Вы точно хотите удалить структуру?
                            </label>
                        </div>
                        <div className="flex flex-row mt-5 text-right justify-end">
                            <button
                                className="px-4 py-1 rounded-full shadow-md text-xs text-white bg-[#494849] hover:bg-[#656365]"
                                onClick={() => handlePopupDeleteStructure('')}
                            >
                                Отмена
                            </button>
                            <button
                                className={`flex px-4 py-1 w-24 h-7 rounded-full shadow-md text-xs text-white ml-2 items-center justify-center bg-[#494849] hover:bg-[#701F1F]`}
                                onClick={handleDeleteStructure} // Вызвать handleAdd
                            >
                                {isDeleting ? (
                                    <svg
                                        className="animate-spin h-4 w-4 text-white"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                    >
                                        <circle
                                            className="opacity-25"
                                            cx="12"
                                            cy="12"
                                            r="10"
                                            stroke="currentColor"
                                            strokeWidth="4"
                                        ></circle>
                                        <path
                                            className="opacity-75"
                                            fill="currentColor"
                                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                                        ></path>
                                    </svg>
                                ) : (
                                    'Удалить'
                                )}
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default OrgStructurePage
