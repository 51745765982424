import React, { useState, useEffect } from 'react'

import { useNavigate, useParams } from 'react-router-dom'
import { PlanPosition, PlanPositionStage } from './types'
import { Plan } from '../types'

import './style.css'

import '../../../../components/Toolbar/Icon.css'
import PlanPositionsHeader from './components/Header'
import PlanPositionsTabs from './components/Tabs'
import PlanPositionsTableRow from './components/TableRow'

const CURRENCY = new Map([
    ['643', '₽'],
    ['156', '¥'],
    ['978', '€'],
    ['840', '$'],
])

interface ToEisSendInfo {
    modification_description: string
    additional_info: string
    approve_date: string
}

interface TakePlanFromEis {
    plan_guid: string
    registration_number: string
}

type ApiResponse = {
    message: Plan[]
}

const getCurrentDate = (): string => {
    const today = new Date()
    const year = today.getFullYear()
    const month = String(today.getMonth() + 1).padStart(2, '0') // добавляем 1, так как месяцы начинаются с 0
    const day = String(today.getDate()).padStart(2, '0')
    return `${year}-${month}-${day}`
}

const PlanPositionListPage: React.FC = () => {
    const navigate = useNavigate()
    const { plan_guid: routePlanGuid } = useParams()
    const [planGuidNav, setPlanGuidNav] = useState(routePlanGuid)

    const [stage, setStage] = useState<PlanPositionStage>(
        PlanPositionStage.EDIT
    )

    const [isLoadingPlan, setLoadingPlan] = useState<boolean>(true)
    const [plan, setPlan] = useState<Plan[]>([])

    const [isLoading, setLoading] = useState<boolean>(true)
    const [items, setItems] = useState<PlanPosition[]>([])

    const [selectedItems, setSelectedItems] = useState<string[]>([])
    const [selectAll, setSelectAll] = useState<boolean>(false)

    const [deletePlanPositionPopup, setDeletePlanPositionPopup] =
        useState<boolean>(false)
    const [isDeleting, setDeleting] = useState<boolean>(false)
    const [deleteItems, setDeleteItems] = useState<PlanPosition[]>([])

    const [copyPlanPositionPopup, setCopyPlanPositionPopup] =
        useState<boolean>(false)
    const [isCopying, setCopying] = useState<boolean>(false)
    const [copyItems, setCopyItems] = useState<PlanPosition[]>([])

    const [statusChangePlanPositionPopup, setStatusChangePlanPositionPopup] =
        useState<boolean>(false)
    const [isStatusChanging, setStatusChanging] = useState<boolean>(false)
    const [statusChangeItems, setStatusChangeItems] = useState<PlanPosition[]>(
        []
    )
    const [statusChangeFlag, setStatusChangeFlag] = useState('')

    const [stageChangePlanPositionPopup, setStageChangePlanPositionPopup] =
        useState<boolean>(false)
    const [isStageChanging, setStageChanging] = useState<boolean>(false)
    const [stageChangeItems, setStageChangeItems] = useState<PlanPosition[]>([])
    const [stageChangeFlag, setStageChangeFlag] = useState('')

    const [toEisSendPopup, setToEisSendPopup] = useState<boolean>(false)
    const [isToEisSending, setToEisSending] = useState<boolean>(false)
    const [toEisSendInfo, setToEisSendInfo] = useState<ToEisSendInfo>({
        modification_description: '',
        additional_info: '',
        approve_date: getCurrentDate(), // текущая дата
    })

    const [updateFromEisPopup, setUpdateFromEisPopup] = useState<boolean>(false)
    const [isUpdatingFromEis, setUpdatingFromEis] = useState<boolean>(false)
    const [popupMessage, setPopupMessage] = useState('')
    const [forceUpdateUrl, setForceUpdateUrl] = useState<string | null>(null)

    const [takeFromEisPopup, setTakeFromEisPopup] = useState<boolean>(false)
    const [isTakingFromEis, setTakingFromEis] = useState<boolean>(false)
    const [takePlanFromEisInfo, setTakePlanFromEisInfo] =
        useState<TakePlanFromEis>({
            plan_guid: '',
            registration_number: '',
        })

    const loadPlan = () => {
        setLoadingPlan(true)

        fetch('https://itzakupki.ru/api/backend/v1/plans', {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then((response) => {
                if (response.ok) {
                    return response.json()
                } else {
                    throw new Error('Failed to fetch plan')
                }
            })
            .then((response: ApiResponse) => response.message)
            .catch((e) => {
                console.error(e)
                return []
            })
            .then((response) => {
                setPlan(
                    response.filter((plans: Plan) => plans.guid === planGuidNav)
                )
            })
            .finally(() => {
                setLoadingPlan(false)
            })
    }

    const loadPlanPositions = () => {
        setLoading(true)

        const postfix =
            stage === PlanPositionStage.PURCHASE_PLAN_IN_EIS ? '' : '_draft'

        fetch(
            'https://itzakupki.ru/api/backend/v1/plan_positions' +
                postfix +
                '/?plan_guid=' +
                encodeURIComponent(planGuidNav as string),
            {
                method: 'GET',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        )
            .then((response) => {
                if (response.ok) {
                    return response.json()
                } else {
                    throw new Error('Failed to fetch plan positions')
                }
            })
            .catch((e) => {
                console.error(e)
                return []
            })
            .then((data) => {
                setItems(
                    data.filter(
                        (item: PlanPosition) => item.position_stage === stage
                    )
                )
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const deletePosition = async (
        planGuid: string,
        guid: string
    ): Promise<void> => {
        const url = `https://itzakupki.ru/api/backend/v1/plan_position_draft/?plan_guid=${planGuid}&guid=${guid}`

        try {
            const response = await fetch(url, {
                method: 'DELETE',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
            })

            if (!response.ok) {
                throw new Error(
                    `Failed to delete position. Status: ${response.status}`
                )
            }

            console.log('Position deleted successfully')
        } catch (error) {
            console.error('Error while deleting position:', error)
            setDeleting(false)
        } finally {
        }
    }

    const copyPosition = async (
        planGuid: string,
        guid: string
    ): Promise<void> => {
        const url = `https://itzakupki.ru/api/backend/v1/plan_position_draft_new_from/?plan_guid=${planGuid}&from_guid=${guid}`

        try {
            const response = await fetch(url, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
            })

            if (!response.ok) {
                throw new Error(
                    `Failed to create new position from. Status: ${response.status}`
                )
            }

            console.log('Position created successfully')
        } catch (error) {
            console.error('Error while creating position:', error)
            setCopying(false)
        } finally {
        }
    }

    const statusChangePosition = async (
        planGuid: string,
        guid: string,
        statusPos: string
    ): Promise<void> => {
        const url = `https://itzakupki.ru/api/backend/v1/plan_positions_draft_change_status/?plan_guid=${planGuid}&pos_guid=${guid}`

        try {
            const response = await fetch(url, {
                method: 'PUT',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ position_status: statusPos }),
            })

            if (!response.ok) {
                throw new Error(
                    `Failed to change status position from. Status: ${response.status}`
                )
            }

            console.log('Position status change successfully')
        } catch (error) {
            console.error('Error while changing status position:', error)
            setStatusChanging(false)
        } finally {
        }
    }

    const stageChangePosition = async (
        planGuid: string,
        guid: string,
        stagePos: string
    ): Promise<void> => {
        const url = `https://itzakupki.ru/api/backend/v1/plan_positions_draft_change_stage/?plan_guid=${planGuid}&pos_guid=${guid}`

        try {
            const response = await fetch(url, {
                method: 'PUT',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ position_stage: stagePos }),
            })

            if (!response.ok) {
                throw new Error(
                    `Failed to change stage position from. Status: ${response.status}`
                )
            }

            console.log('Position stage change successfully')
        } catch (error) {
            console.error('Error while changing stage position:', error)
            setStageChanging(false)
        } finally {
        }
    }

    const toEisSendPlan = async (
        planGuid: string,
        send_info: ToEisSendInfo
    ): Promise<void> => {
        const url = `https://itzakupki.ru/api/backend/v1/plan_to_eis?plan_guid=${planGuid}`

        try {
            const response = await fetch(url, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(send_info),
            })

            if (!response.ok) {
                throw new Error(
                    `Failed to send plan to EIS from. Status: ${response.status}`
                )
            }

            console.log('Position send plan to EIS successfully')
        } catch (error) {
            console.error('Error while send plan to EIS:', error)
            setCopying(false)
        } finally {
        }
    }

    const updatePlanFromEis = async (reg_number: number): Promise<void> => {
        const url = `https://itzakupki.ru/api/backend/v1/plan_from_eis`
        setUpdatingFromEis(true)

        try {
            const response = await fetch(url, {
                method: 'PUT',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ reg_number: reg_number }),
            })

            if (response.ok) {
                const responseData = await response.json()

                // Обработка ошибки одинаковых весрий
                if (
                    responseData.details &&
                    responseData.details.includes('version')
                ) {
                    setPopupMessage(
                        'Версия плана в базе и версия плана в ЕИС одинакова. Хотите продолжить обновление?'
                    )
                    setForceUpdateUrl(
                        `https://itzakupki.ru/api/backend/v1/plan_from_eis?force_new_plan=true&force_update=true`
                    )
                    setUpdateFromEisPopup(true)
                    return
                }

                // Обработка ошибки несовпадения данных
                if (
                    responseData.details &&
                    responseData.details.includes("'is_success': False")
                ) {
                    setPopupMessage(
                        'Данные из ЕИС отличаются от данных в базе. Хотите продолжить обновление?'
                    )
                    setForceUpdateUrl(
                        `https://itzakupki.ru/api/backend/v1/plan_from_eis?force_update=true`
                    )
                    setUpdateFromEisPopup(true)
                    return
                }

                // Обработка c отличием ИНН
                if (
                    responseData.details &&
                    responseData.details.includes('ИНН')
                ) {
                    setPopupMessage(
                        'ИНН плана закупки не соответствует ИНН вашей организации'
                    )
                    return
                }

                // Обработка c отличием ИНН
                if (
                    responseData.details &&
                    responseData.details.includes('multiple plan')
                ) {
                    setPopupMessage(
                        'Невозможно добавить план, так как он уже имеется в базе'
                    )
                    return
                }

                // Обработка успешного ответа
                const newGuid = responseData.plan?.new_guid

                if (newGuid) {
                    setPlanGuidNav(newGuid) // Обновите состояние
                    navigate(`/hub/plans/${newGuid}/positions`)
                    setTakeFromEisPopup(false)
                }
            }

            if (!response.ok) {
                let errorData
                try {
                    errorData = await response.json()
                } catch (e) {
                    console.error('Failed to parse JSON from response:', e)
                    throw new Error(
                        `Unexpected error: Unable to parse JSON. Status: ${response.status}`
                    )
                }
                // Если ошибка не подходит под указанные типы
                throw new Error(
                    `Failed to update plan from EIS. Status: ${response.status}, Message: ${errorData.message}`
                )
            }
        } catch (error) {
            console.error('Error while updating plan from EIS:', error)
            setPopupMessage('Ошибка связывания плана. Проверте номер плана')
        } finally {
            setUpdatingFromEis(false) // Отключаем анимацию загрузки
            setTakingFromEis(false) // Отключаем анимацию загрузки
        }
    }

    const handleForceUpdate = async (reg_number: number) => {
        if (!forceUpdateUrl) return // Если URL отсутствует, ничего не делаем

        setUpdatingFromEis(true)
        try {
            const response = await fetch(forceUpdateUrl, {
                method: 'PUT',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ reg_number: reg_number }), // Используйте правильное значение
            })

            if (!response.ok) {
                throw new Error(
                    `Failed to force update plan. Status: ${response.status}`
                )
            }

            const data = await response.json()
            const newGuid = data.plan?.new_guid

            console.log(newGuid)

            if (newGuid) {
                setPlanGuidNav(newGuid)
                navigate(`/hub/plans/${newGuid}/positions`)
            }
        } catch (error) {
            console.error('Error while forcing update plan:', error)
        } finally {
            setUpdatingFromEis(false) // Закрыть Popup
            setUpdateFromEisPopup(false)
        }
    }

    const takePlanFromEis = async (takeInfo: TakePlanFromEis) => {
        setTakingFromEis(true) // Включаем анимацию загрузки
        try {
            const response = await fetch(
                `https://itzakupki.ru/api/backend/v1/plans/update_registration_number`,
                {
                    method: 'PUT',
                    credentials: 'include',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(takeInfo), // Добавлено тело запроса
                }
            )
            if (!response.ok) {
                throw new Error('Ошибка при добавлении плана')
            }
            updatePlanFromEis(Number(takeInfo.registration_number))
        } catch (error) {
            console.error('Ошибка при добавлении плана:', error)
        } finally {
            loadPlan()
        }
    }

    useEffect(() => {
        // Синхронизируйте локальное состояние с маршрутом
        loadPlan()
        setTakePlanFromEisInfo((prev) => ({
            ...prev,
            ['plan_guid']: planGuidNav ? planGuidNav : '',
        }))
    }, [])

    // initial load & on tab change
    useEffect(() => {
        loadPlanPositions()
        setSelectedItems([])
    }, [stage])

    useEffect(() => {
        // Синхронизируйте локальное состояние с маршрутом
        setPlanGuidNav(routePlanGuid)
    }, [routePlanGuid])

    useEffect(() => {
        // Синхронизируйте локальное состояние с маршрутом
        loadPlanPositions()
    }, [planGuidNav])

    // Функция для обработки изменения состояния отдельного checkbox
    const handleCheckboxChange = (pos_guid: string) => {
        setSelectedItems((prevSelectedItems) => {
            if (prevSelectedItems.includes(pos_guid)) {
                return prevSelectedItems.filter((itemId) => itemId !== pos_guid)
            } else {
                return [...prevSelectedItems, pos_guid]
            }
        })
    }

    // Функция для обработки изменения состояния "Select All"
    const handleSelectAllChange = () => {
        setSelectAll(!selectAll)
        if (!selectAll) {
            // Выбираем только те элементы, которые соответствуют текущей стадии
            const filteredItems = items
                .filter((item) => item.position_stage === stage)
                .map((item) => item.guid)

            setSelectedItems(filteredItems)
        } else {
            // Сбрасываем выбор всех элементов
            setSelectedItems([])
        }
    }

    const handleDeletePlanPositionPopup = () => {
        if (deletePlanPositionPopup == false) {
            const filteredData = items.filter((item) =>
                selectedItems.includes(item.guid)
            )
            setDeleteItems(filteredData)
        }
        setDeletePlanPositionPopup(!deletePlanPositionPopup)
    }

    const handleDeleteClick = async () => {
        setDeleting(true)

        let remainingDeleteItems = [...selectedItems] // Копируем массив, чтобы избежать изменения оригинала.

        for (const item of selectedItems) {
            await deletePosition(String(planGuidNav), item) // Отправляем запрос на удаление.
            // Уменьшаем массив, убирая удалённый элемент.
            remainingDeleteItems = remainingDeleteItems.filter(
                (i) => i !== item
            )
            const filteredDeleteItems = deleteItems.filter((delItem) =>
                remainingDeleteItems.includes(delItem.guid)
            )
            setDeleteItems(filteredDeleteItems)
        }
        setSelectedItems([])
        loadPlanPositions()
        setSelectAll(false)
        setDeleting(false)
        setDeletePlanPositionPopup(false)
    }

    const handleCopyPlanPositionPopup = () => {
        if (copyPlanPositionPopup == false) {
            const filteredData = items.filter((item) =>
                selectedItems.includes(item.guid)
            )
            setCopyItems(filteredData)
        }
        setCopyPlanPositionPopup(!copyPlanPositionPopup)
    }

    const handleCopyClick = async () => {
        setCopying(true)

        let remainingCopyItems = [...selectedItems] // Копируем массив, чтобы избежать изменения оригинала.

        for (const item of selectedItems) {
            await copyPosition(String(planGuidNav), item) // Отправляем запрос на удаление.
            remainingCopyItems = remainingCopyItems.filter((i) => i !== item)

            const filteredCopyItems = copyItems.filter((copyItem) =>
                remainingCopyItems.includes(copyItem.guid)
            )
            setCopyItems(filteredCopyItems)
        }
        setSelectedItems([])
        setSelectAll(false)
        setCopying(false)
        setCopyPlanPositionPopup(false)
        setStage(PlanPositionStage.EDIT)
    }

    const handleStatusChangePlanPositionPopup = (statusPos: string) => {
        if (statusChangePlanPositionPopup == false) {
            const filteredData = items.filter((item) =>
                selectedItems.includes(item.guid)
            )
            setStatusChangeItems(filteredData)
        }
        setStatusChangePlanPositionPopup(!statusChangePlanPositionPopup)
        setStatusChangeFlag(statusPos)
    }

    const handleStatusChangeClick = async () => {
        setStatusChanging(true)

        let remainingStatusChangeItems = [...selectedItems] // Копируем массив, чтобы избежать изменения оригинала.

        for (const item of selectedItems) {
            await statusChangePosition(
                String(planGuidNav),
                item,
                statusChangeFlag
            ) // Отправляем запрос на удаление.
            remainingStatusChangeItems = remainingStatusChangeItems.filter(
                (i) => i !== item
            )

            const filteredStatusChangeItems = statusChangeItems.filter(
                (statusChange) =>
                    remainingStatusChangeItems.includes(statusChange.guid)
            )
            setStatusChangeItems(filteredStatusChangeItems)
        }
        setSelectedItems([])
        loadPlanPositions()
        setSelectAll(false)
        setStatusChanging(false)
        setStatusChangeFlag('')
        setStatusChangePlanPositionPopup(false)
    }

    const handleStageChangePlanPositionPopup = (stagePos: string) => {
        if (stageChangePlanPositionPopup == false) {
            const filteredData = items.filter((item) =>
                selectedItems.includes(item.guid)
            )
            setStageChangeItems(filteredData)
        }
        setStageChangePlanPositionPopup(!stageChangePlanPositionPopup)
        setStageChangeFlag(stagePos)
        if (stage == 'Редактирование') {
            if (stagePos == 'Подготовка к размещению') {
                setStatusChangeFlag('Подготовка')
            }
        } else if (stage == 'Подготовка к размещению') {
            if (stagePos == 'Отправка в ЕИС') {
                setStatusChangeFlag('Готово к отправке')
            } else if (stagePos == 'Редактирование') {
                setStatusChangeFlag('Черновик')
            }
        } else if (stage == 'Отправка в ЕИС') {
            if (stagePos == 'Подготовка к размещению') {
                setStatusChangeFlag('Подготовка')
            }
        }
    }

    const handleStageChangeClick = async () => {
        setStageChanging(true)

        let remainingStageChangeItems = [...selectedItems] // Копируем массив, чтобы избежать изменения оригинала.

        for (const item of selectedItems) {
            await statusChangePosition(
                String(planGuidNav),
                item,
                statusChangeFlag
            ) // Отправляем запрос на удаление.
            await stageChangePosition(
                String(planGuidNav),
                item,
                stageChangeFlag
            ) // Отправляем запрос на удаление.
            remainingStageChangeItems = remainingStageChangeItems.filter(
                (i) => i !== item
            )

            const filteredStageChangeItems = stageChangeItems.filter(
                (stageChange) =>
                    remainingStageChangeItems.includes(stageChange.guid)
            )
            setStageChangeItems(filteredStageChangeItems)
        }
        setSelectedItems([])
        loadPlanPositions()
        setSelectAll(false)
        setStageChanging(false)
        setStageChangeFlag('')
        setStatusChangeFlag('')
        setStageChangePlanPositionPopup(false)
    }

    const handleToEisSendPopup = () => {
        setToEisSendPopup(!toEisSendPopup)
    }

    const handleToEisSendClick = async () => {
        setToEisSending(true)
        await toEisSendPlan(String(planGuidNav), toEisSendInfo)
        setToEisSending(false)
        loadPlanPositions()
        setToEisSendPopup(false)
    }

    const handleInputChange = (
        fieldName: keyof ToEisSendInfo,
        value: string
    ) => {
        setToEisSendInfo((prev) => ({
            ...prev,
            [fieldName]: value,
        }))
    }

    const handleUpdateFromEisPopup = () => {
        setUpdateFromEisPopup(!updateFromEisPopup)
        setPopupMessage('')
    }

    const handleTakeFromEisPopup = () => {
        setTakeFromEisPopup(!takeFromEisPopup)
        setPopupMessage('')
    }

    const handleTakeFromEisClick = async () => {
        await takePlanFromEis(takePlanFromEisInfo)
    }

    const handleInputPlanNumberChange = (
        fieldName: keyof TakePlanFromEis,
        value: string
    ) => {
        setTakePlanFromEisInfo((prev) => ({
            ...prev,
            [fieldName]: value,
        }))
    }

    const initialValue = 0
    const sum = items.reduce(
        (accumulator, currentValue) =>
            accumulator + Number(currentValue.maximum_contract_price),
        initialValue
    )

    return (
        <div className="h-full flex flex-col bg-[#181818]">
            <div className="bg-[#111111]">
                <PlanPositionsHeader
                    positionCount={items.length}
                    sum={sum}
                    plan={plan.length > 0 ? plan[0] : undefined}
                    loadPlan={!isLoadingPlan}
                />
                <PlanPositionsTabs
                    stage={stage}
                    onTabChange={(newStage) => {
                        setStage(newStage)
                    }}
                    onTabClick={(selectAllFlag) => setSelectAll(selectAllFlag)}
                />
            </div>

            {/* // body */}
            <div className="plan-positions flex p-5 h-[calc(100vh_-_96px_-_28px)]">
                {/* table */}
                <div className="flex flex-col w-full bg-[#1D1C1E] rounded">
                    {/* toolbar */}
                    <div className="flex flex-row gap-1 text-white p-2 h-10 bg-[#333033] rounded-t">
                        <div className="w-6 h-6 grid rounded-full place-items-center bg-[#333033] hover:bg-[#656365]">
                            <input
                                type="checkbox"
                                className="p-2"
                                checked={selectAll}
                                onChange={handleSelectAllChange}
                            />
                        </div>

                        <button
                            className={`w-6 h-6 grid rounded-full place-items-center bg-[#333033] hover:bg-[#656365]`}
                            onClick={() =>
                                navigate(
                                    `/hub/plans/${planGuidNav}/positions/new`
                                )
                            }
                            title="Создать"
                        >
                            <svg
                                className="w-3 h-3"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 22 22"
                                id="add"
                            >
                                <path
                                    fill="#FFFFFF"
                                    d="M19,11H13V5a1,1,0,0,0-2,0v6H5a1,1,0,0,0,0,2h6v6a1,1,0,0,0,2,0V13h6a1,1,0,0,0,0-2Z"
                                ></path>
                            </svg>
                        </button>

                        <button
                            className={`w-6 h-6 grid rounded-full place-items-center bg-[#333033] ${!selectedItems.length ? '' : 'hover:bg-[#656365]'}`}
                            disabled={!selectedItems.length ? true : false}
                            onClick={handleCopyPlanPositionPopup}
                            title="Копировать"
                        >
                            <svg
                                className="w-3 h-3"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 22 22"
                                id="copy"
                            >
                                <path
                                    fill={
                                        !selectedItems.length
                                            ? '#FFFFFF44'
                                            : '#FFFFFF'
                                    }
                                    d="M21,8.94a1.31,1.31,0,0,0-.06-.27l0-.09a1.07,1.07,0,0,0-.19-.28h0l-6-6h0a1.07,1.07,0,0,0-.28-.19.32.32,0,0,0-.09,0A.88.88,0,0,0,14.05,2H10A3,3,0,0,0,7,5V6H6A3,3,0,0,0,3,9V19a3,3,0,0,0,3,3h8a3,3,0,0,0,3-3V18h1a3,3,0,0,0,3-3V9S21,9,21,8.94ZM15,5.41,17.59,8H16a1,1,0,0,1-1-1ZM15,19a1,1,0,0,1-1,1H6a1,1,0,0,1-1-1V9A1,1,0,0,1,6,8H7v7a3,3,0,0,0,3,3h5Zm4-4a1,1,0,0,1-1,1H10a1,1,0,0,1-1-1V5a1,1,0,0,1,1-1h3V7a3,3,0,0,0,3,3h3Z"
                                ></path>
                            </svg>
                        </button>

                        {stage == 'Редактирование' ? (
                            <>
                                <button
                                    className={`w-6 h-6 grid rounded-full place-items-center bg-[#333033] ${!selectedItems.length ? '' : 'hover:bg-[#656365]'} `}
                                    onClick={handleDeletePlanPositionPopup}
                                    disabled={
                                        !selectedItems.length ? true : false
                                    }
                                    title="Удалить"
                                >
                                    <svg
                                        className="w-3 h-3"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 22 22"
                                        id="trash-alt"
                                    >
                                        <path
                                            fill={
                                                !selectedItems.length
                                                    ? '#FFFFFF44'
                                                    : '#FFFFFF'
                                            }
                                            d="M10,18a1,1,0,0,0,1-1V11a1,1,0,0,0-2,0v6A1,1,0,0,0,10,18ZM20,6H16V5a3,3,0,0,0-3-3H11A3,3,0,0,0,8,5V6H4A1,1,0,0,0,4,8H5V19a3,3,0,0,0,3,3h8a3,3,0,0,0,3-3V8h1a1,1,0,0,0,0-2ZM10,5a1,1,0,0,1,1-1h2a1,1,0,0,1,1,1V6H10Zm7,14a1,1,0,0,1-1,1H8a1,1,0,0,1-1-1V8H17Zm-3-1a1,1,0,0,0,1-1V11a1,1,0,0,0-2,0v6A1,1,0,0,0,14,18Z"
                                        ></path>
                                    </svg>
                                </button>
                                <button
                                    className={`w-6 h-6 grid rounded-full place-items-center bg-[#333033] ${!selectedItems.length ? '' : 'hover:bg-[#656365]'} `}
                                    onClick={() =>
                                        handleStatusChangePlanPositionPopup(
                                            'Черновик'
                                        )
                                    }
                                    disabled={
                                        !selectedItems.length ? true : false
                                    }
                                    title="Черновик"
                                >
                                    <svg
                                        className="w-3 h-3"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 22 22"
                                        id="check"
                                    >
                                        <path
                                            fill={
                                                !selectedItems.length
                                                    ? '#FFFFFF44'
                                                    : '#FFFFFF'
                                            }
                                            d="M13.41,12l4.3-4.29a1,1,0,1,0-1.42-1.42L12,10.59,7.71,6.29A1,1,0,0,0,6.29,7.71L10.59,12l-4.3,4.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L12,13.41l4.29,4.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42Z"
                                        ></path>
                                    </svg>
                                </button>
                                <button
                                    className={`w-6 h-6 grid rounded-full place-items-center bg-[#333033] ${!selectedItems.length ? '' : 'hover:bg-[#656365]'} `}
                                    onClick={() =>
                                        handleStatusChangePlanPositionPopup(
                                            'Готово'
                                        )
                                    }
                                    disabled={
                                        !selectedItems.length ? true : false
                                    }
                                    title="Готово"
                                >
                                    <svg
                                        className="w-3 h-3"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 22 22"
                                        id="check"
                                    >
                                        <path
                                            fill={
                                                !selectedItems.length
                                                    ? '#FFFFFF44'
                                                    : '#FFFFFF'
                                            }
                                            d="M18.71,7.21a1,1,0,0,0-1.42,0L9.84,14.67,6.71,11.53A1,1,0,1,0,5.29,13l3.84,3.84a1,1,0,0,0,1.42,0l8.16-8.16A1,1,0,0,0,18.71,7.21Z"
                                        ></path>
                                    </svg>
                                </button>
                                <button
                                    className={`w-6 h-6 grid rounded-full place-items-center bg-[#333033] ${!selectedItems.length ? '' : 'hover:bg-[#656365]'} `}
                                    onClick={() =>
                                        handleStageChangePlanPositionPopup(
                                            'Подготовка к размещению'
                                        )
                                    }
                                    disabled={
                                        !selectedItems.length ? true : false
                                    }
                                    title="Подготовка к размещению"
                                >
                                    <svg
                                        className="w-3 h-3"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 22 22"
                                        id="arrow-right"
                                    >
                                        <path
                                            fill={
                                                !selectedItems.length
                                                    ? '#FFFFFF44'
                                                    : '#FFFFFF'
                                            }
                                            d="M17.92,11.62a1,1,0,0,0-.21-.33l-5-5a1,1,0,0,0-1.42,1.42L14.59,11H7a1,1,0,0,0,0,2h7.59l-3.3,3.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0l5-5a1,1,0,0,0,.21-.33A1,1,0,0,0,17.92,11.62Z"
                                        ></path>
                                    </svg>
                                </button>
                            </>
                        ) : (
                            ''
                        )}
                        {stage == 'Подготовка к размещению' ? (
                            <>
                                <button
                                    className={`w-6 h-6 grid rounded-full place-items-center bg-[#333033] ${!selectedItems.length ? '' : 'hover:bg-[#656365]'} `}
                                    onClick={() =>
                                        handleStageChangePlanPositionPopup(
                                            'Редактирование'
                                        )
                                    }
                                    disabled={
                                        !selectedItems.length ? true : false
                                    }
                                    title="Редактирование"
                                >
                                    <svg
                                        className="w-3 h-3"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 22 22"
                                        id="arrow-right"
                                    >
                                        <path
                                            fill={
                                                !selectedItems.length
                                                    ? '#FFFFFF44'
                                                    : '#FFFFFF'
                                            }
                                            d="M17,11H9.41l3.3-3.29a1,1,0,1,0-1.42-1.42l-5,5a1,1,0,0,0-.21.33,1,1,0,0,0,0,.76,1,1,0,0,0,.21.33l5,5a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42L9.41,13H17a1,1,0,0,0,0-2Z"
                                        ></path>
                                    </svg>
                                </button>
                                <button
                                    className={`w-6 h-6 grid rounded-full place-items-center bg-[#333033] ${!selectedItems.length ? '' : 'hover:bg-[#656365]'} `}
                                    onClick={() =>
                                        handleStatusChangePlanPositionPopup(
                                            'Подготовка'
                                        )
                                    }
                                    disabled={
                                        !selectedItems.length ? true : false
                                    }
                                    title="Подготовка"
                                >
                                    <svg
                                        className="w-3 h-3"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 22 22"
                                        id="check"
                                    >
                                        <path
                                            fill={
                                                !selectedItems.length
                                                    ? '#FFFFFF44'
                                                    : '#FFFFFF'
                                            }
                                            d="M13.41,12l4.3-4.29a1,1,0,1,0-1.42-1.42L12,10.59,7.71,6.29A1,1,0,0,0,6.29,7.71L10.59,12l-4.3,4.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L12,13.41l4.29,4.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42Z"
                                        ></path>
                                    </svg>
                                </button>
                                <button
                                    className={`w-6 h-6 grid rounded-full place-items-center bg-[#333033] ${!selectedItems.length ? '' : 'hover:bg-[#656365]'} `}
                                    onClick={() =>
                                        handleStatusChangePlanPositionPopup(
                                            'Готово'
                                        )
                                    }
                                    disabled={
                                        !selectedItems.length ? true : false
                                    }
                                    title="Готово"
                                >
                                    <svg
                                        className="w-3 h-3"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 22 22"
                                        id="check"
                                    >
                                        <path
                                            fill={
                                                !selectedItems.length
                                                    ? '#FFFFFF44'
                                                    : '#FFFFFF'
                                            }
                                            d="M18.71,7.21a1,1,0,0,0-1.42,0L9.84,14.67,6.71,11.53A1,1,0,1,0,5.29,13l3.84,3.84a1,1,0,0,0,1.42,0l8.16-8.16A1,1,0,0,0,18.71,7.21Z"
                                        ></path>
                                    </svg>
                                </button>
                                <button
                                    className={`w-6 h-6 grid rounded-full place-items-center bg-[#333033] ${!selectedItems.length ? '' : 'hover:bg-[#656365]'} `}
                                    onClick={() =>
                                        handleStageChangePlanPositionPopup(
                                            'Отправка в ЕИС'
                                        )
                                    }
                                    disabled={
                                        !selectedItems.length ? true : false
                                    }
                                    title="Отправка в ЕИС"
                                >
                                    <svg
                                        className="w-3 h-3"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 22 22"
                                        id="arrow-right"
                                    >
                                        <path
                                            fill={
                                                !selectedItems.length
                                                    ? '#FFFFFF44'
                                                    : '#FFFFFF'
                                            }
                                            d="M17.92,11.62a1,1,0,0,0-.21-.33l-5-5a1,1,0,0,0-1.42,1.42L14.59,11H7a1,1,0,0,0,0,2h7.59l-3.3,3.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0l5-5a1,1,0,0,0,.21-.33A1,1,0,0,0,17.92,11.62Z"
                                        ></path>
                                    </svg>
                                </button>
                            </>
                        ) : (
                            ''
                        )}
                        {stage == 'Отправка в ЕИС' ? (
                            <>
                                <button
                                    className={`w-6 h-6 grid rounded-full place-items-center bg-[#333033] ${!selectedItems.length ? '' : 'hover:bg-[#656365]'} `}
                                    onClick={() =>
                                        handleStageChangePlanPositionPopup(
                                            'Подготовка к размещению'
                                        )
                                    }
                                    disabled={
                                        !selectedItems.length ? true : false
                                    }
                                    title="Подготовка к размещению"
                                >
                                    <svg
                                        className="w-3 h-3"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 22 22"
                                        id="arrow-right"
                                    >
                                        <path
                                            fill={
                                                !selectedItems.length
                                                    ? '#FFFFFF44'
                                                    : '#FFFFFF'
                                            }
                                            d="M17,11H9.41l3.3-3.29a1,1,0,1,0-1.42-1.42l-5,5a1,1,0,0,0-.21.33,1,1,0,0,0,0,.76,1,1,0,0,0,.21.33l5,5a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42L9.41,13H17a1,1,0,0,0,0-2Z"
                                        ></path>
                                    </svg>
                                </button>
                                <button
                                    className={`w-6 h-6 grid rounded-full place-items-center bg-[#333033] ${!items.length ? '' : 'hover:bg-[#656365]'} `}
                                    onClick={handleToEisSendPopup}
                                    disabled={!items.length ? true : false}
                                    title="Интеграция"
                                >
                                    <svg
                                        className="w-3 h-3"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 22 22"
                                        id="integration"
                                    >
                                        <path
                                            fill={
                                                !items.length
                                                    ? '#FFFFFF44'
                                                    : '#FFFFFF'
                                            }
                                            d="M18,14a4,4,0,0,0-3.08,1.48l-5.1-2.35a3.64,3.64,0,0,0,0-2.26l5.1-2.35A4,4,0,1,0,14,6a4.17,4.17,0,0,0,.07.71L8.79,9.14a4,4,0,1,0,0,5.72l5.28,2.43A4.17,4.17,0,0,0,14,18a4,4,0,1,0,4-4ZM18,4a2,2,0,1,1-2,2A2,2,0,0,1,18,4ZM6,14a2,2,0,1,1,2-2A2,2,0,0,1,6,14Zm12,6a2,2,0,1,1,2-2A2,2,0,0,1,18,20Z"
                                        ></path>
                                    </svg>
                                </button>
                            </>
                        ) : (
                            ''
                        )}
                        {stage == 'План закупки в ЕИС' ? (
                            <>
                                <button
                                    className={`w-6 h-6 grid rounded-full place-items-center bg-[#333033] ${!items.length ? '' : 'hover:bg-[#656365]'} `}
                                    onClick={() =>
                                        updatePlanFromEis(
                                            plan[0]?.registration_number
                                        )
                                    }
                                    disabled={!items.length ? true : false}
                                    title="Обновить план из ЕИС"
                                >
                                    {isUpdatingFromEis ? (
                                        <svg
                                            className="animate-spin h-4 w-4 text-white"
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                        >
                                            <circle
                                                className="opacity-25"
                                                cx="12"
                                                cy="12"
                                                r="10"
                                                stroke="currentColor"
                                                strokeWidth="4"
                                            ></circle>
                                            <path
                                                className="opacity-75"
                                                fill="currentColor"
                                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                                            ></path>
                                        </svg>
                                    ) : (
                                        <svg
                                            className="w-3 h-3"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 22 22"
                                            id="update"
                                        >
                                            <path
                                                fill={
                                                    !items.length
                                                        ? '#FFFFFF44'
                                                        : '#FFFFFF'
                                                }
                                                d="M19.91,15.51H15.38a1,1,0,0,0,0,2h2.4A8,8,0,0,1,4,12a1,1,0,0,0-2,0,10,10,0,0,0,16.88,7.23V21a1,1,0,0,0,2,0V16.5A1,1,0,0,0,19.91,15.51ZM12,2A10,10,0,0,0,5.12,4.77V3a1,1,0,0,0-2,0V7.5a1,1,0,0,0,1,1h4.5a1,1,0,0,0,0-2H6.22A8,8,0,0,1,20,12a1,1,0,0,0,2,0A10,10,0,0,0,12,2Z"
                                            ></path>
                                        </svg>
                                    )}
                                </button>
                                {!items.length && !isLoading ? (
                                    <button
                                        className={`w-6 h-6 grid rounded-full place-items-center bg-[#333033] hover:bg-[#656365] `}
                                        onClick={handleTakeFromEisPopup}
                                        title="Связать с планом"
                                    >
                                        {isTakingFromEis ? (
                                            <svg
                                                className="animate-spin h-4 w-4 text-white"
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                            >
                                                <circle
                                                    className="opacity-25"
                                                    cx="12"
                                                    cy="12"
                                                    r="10"
                                                    stroke="currentColor"
                                                    strokeWidth="4"
                                                ></circle>
                                                <path
                                                    className="opacity-75"
                                                    fill="currentColor"
                                                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                                                ></path>
                                            </svg>
                                        ) : (
                                            <svg
                                                className="w-3 h-3"
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 22 22"
                                                id="link"
                                            >
                                                <path
                                                    fill={
                                                        items.length
                                                            ? '#FFFFFF44'
                                                            : '#FFFFFF'
                                                    }
                                                    d="M8,12a1,1,0,0,0,1,1h6a1,1,0,0,0,0-2H9A1,1,0,0,0,8,12Zm2,3H7A3,3,0,0,1,7,9h3a1,1,0,0,0,0-2H7A5,5,0,0,0,7,17h3a1,1,0,0,0,0-2Zm7-8H14a1,1,0,0,0,0,2h3a3,3,0,0,1,0,6H14a1,1,0,0,0,0,2h3A5,5,0,0,0,17,7Z"
                                                ></path>
                                            </svg>
                                        )}
                                    </button>
                                ) : (
                                    ''
                                )}
                            </>
                        ) : (
                            ''
                        )}
                    </div>
                    {/* name */}
                    <div className="flex flex-row gap-1 h-24 bg-[#2A282B] text-[#8A8A8A] text-[10px] p-1 ">
                        <div className="grid w-8 place-items-center text-center"></div>
                        <div className="grid w-12 place-items-center text-center">
                            П/п
                        </div>
                        <div className="grid w-12 place-items-center text-center">
                            Номер позиции в ЕИС
                        </div>
                        <div className="grid w-24 place-items-center text-center">
                            Стадия
                        </div>
                        <div className="grid w-24 place-items-center text-center">
                            Статус
                        </div>
                        <div className="flex-1 grid place-items-center text-center min-w-44">
                            Предмет договора
                        </div>
                        <div className="grid w-28 place-items-center text-center">
                            Начальная (максимальная) цена договора
                        </div>
                        <div className="grid w-24 place-items-center text-center">
                            Дата (период) размещения извещения
                        </div>
                        <div className="grid w-24 place-items-center text-center">
                            Срок исполнения договора
                        </div>
                        <div className="grid w-44 place-items-center text-center">
                            Способ закупки
                        </div>
                        <div className="grid w-24 place-items-center text-center">
                            Статус в плане закупки
                        </div>
                        <div className="grid w-20 place-items-center text-center">
                            Тип объекта закупки
                        </div>
                        <div className="grid w-20 place-items-center text-center">
                            Подразделение
                        </div>
                        <div className="grid w-20 place-items-center text-center">
                            Статья
                        </div>
                        <div className="grid w-28 place-items-center text-center">
                            Номер в системе
                        </div>
                        <div className="grid w-20 place-items-center text-center pr-5">
                            Дата последнего изменения
                        </div>
                        {/* <div className="grid w-12 place-items-center text-center">
                            ДД
                        </div> */}
                    </div>

                    {/* data */}
                    <div className="flex flex-col overflow-y-auto gap-1 my-1 h-dvh">
                        {/* Вывод позиций */}
                        <>
                            {isLoading ? (
                                <div className="flex w-full h-full items-center justify-center">
                                    <svg
                                        className="animate-spin h-10 w-10 text-white"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                    >
                                        <circle
                                            className="opacity-25"
                                            cx="12"
                                            cy="12"
                                            r="10"
                                            stroke="currentColor"
                                            strokeWidth="4"
                                        ></circle>
                                        <path
                                            className="opacity-75"
                                            fill="currentColor"
                                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                                        ></path>
                                    </svg>
                                </div>
                            ) : !items.length ? (
                                <div className="flex w-full h-12 items-center justify-center text-[#8A8A8A] text-[11px]">
                                    Нет данных
                                </div>
                            ) : (
                                items.map((item, index) => (
                                    <PlanPositionsTableRow
                                        key={index}
                                        keyindex={index + 1}
                                        planPosition={item}
                                        selected={selectedItems.includes(
                                            item.guid
                                        )}
                                        onClick={(guid) => {
                                            handleCheckboxChange(guid)
                                        }}
                                        onDoubleClick={(pos_guid) => {
                                            const action =
                                                stage ===
                                                PlanPositionStage.PURCHASE_PLAN_IN_EIS
                                                    ? 'edit'
                                                    : 'draft'

                                            navigate(
                                                `/hub/plans/${planGuidNav}/positions/${action}/${pos_guid}`
                                            )
                                        }}
                                    />
                                ))
                            )}
                        </>
                    </div>

                    {/* buttons */}
                    <div className="flex flex-row gap-1 text-white p-2 bg-[#333033] rounded-b h-10">
                        <button
                            className="button"
                            onClick={() =>
                                navigate(
                                    `/hub/plans/${planGuidNav}/positions/new`
                                )
                            }
                        >
                            + Создать позицию
                        </button>
                        <button className="button">Скопировать</button>
                        <button className="button">Печать</button>
                        <button className="button">Скачать</button>
                    </div>
                </div>
            </div>
            {deletePlanPositionPopup && (
                <div
                    className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center"
                    onClick={handleDeletePlanPositionPopup}
                >
                    <div
                        className="p-5 rounded-lg bg-[#262427] w-[600px] max-h-[600px]"
                        onClick={(e) => e.stopPropagation()}
                    >
                        <h2 className="text-white">Удалить позиции?</h2>
                        <div className="flex flex-col mt-2 space-y-1">
                            <label className="text-[12.8px] text-white">
                                Вы точно хотите удалить позиции?
                            </label>
                            <div className="flex flex-row w-full space-x-5 pt-2">
                                <div className="flex flex-col w-full">
                                    {/* name */}
                                    <div className="flex flex-row gap-1 bg-[#333033] text-[#8A8A8A] text-[10px] px-1 py-2 rounded-t-lg">
                                        <div className="flex w-10 items-center justify-center text-center">
                                            № <br />
                                            П/п
                                        </div>
                                        <div className="flex w-10 items-center justify-center text-center">
                                            Номер позиции в ЕИС
                                        </div>
                                        <div className="flex flex-1 items-center justify-center text-center">
                                            Предмет договора
                                        </div>
                                        <div className="flex w-28 items-center justify-center text-center">
                                            Начальная (максимальная) цена
                                            договора
                                        </div>
                                        <div className="flex w-6 items-center justify-center"></div>
                                    </div>
                                    {/* data */}
                                    <div className="flex flex-col h-[200px] overflow-y-auto gap-1 py-1 px-1 grow bg-[#1D1C1E] rounded-b-lg">
                                        <div className="flex flex-col w-full space-y-1">
                                            {deleteItems.map((item, index) => (
                                                <div
                                                    key={item.guid}
                                                    className="flex flex-row w-full rounded bg-[#2A282B] hover:bg-[#373538]"
                                                >
                                                    <div className="flex flex-row w-full">
                                                        <div className="flex flex-row flex-1 gap-1 text-white text-[11px]">
                                                            <div className="flex w-10 items-center justify-center text-[#8A8A8A]">
                                                                {index + 1}
                                                            </div>
                                                            <div className="flex w-10 items-center justify-center">
                                                                {item.ordinal_number
                                                                    ? item.ordinal_number
                                                                    : '-'}
                                                            </div>
                                                            <div className="flex  flex-1 items-center justify-left p-1">
                                                                {item.contract_subject ? (
                                                                    <span className="fourrow">
                                                                        {
                                                                            item.contract_subject
                                                                        }
                                                                    </span>
                                                                ) : (
                                                                    '-'
                                                                )}
                                                            </div>
                                                            <div className="flex w-24 items-center justify-end">
                                                                {Number(
                                                                    item.maximum_contract_price
                                                                ).toLocaleString(
                                                                    'ru-RU',
                                                                    {
                                                                        minimumFractionDigits: 2,
                                                                    }
                                                                )}{' '}
                                                                {item.currency_digital_code
                                                                    ? CURRENCY.get(
                                                                          String(
                                                                              item.currency_digital_code
                                                                          )
                                                                      )
                                                                    : ''}
                                                            </div>
                                                            <div className="flex w-6 items-center justify-center text-[#8A8A8A] "></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-row mt-5 text-right justify-end">
                            {isDeleting ? (
                                ''
                            ) : (
                                <button
                                    className="px-4 py-1 rounded-full shadow-md text-xs text-white bg-green-700 hover:bg-green-600"
                                    onClick={handleDeletePlanPositionPopup}
                                >
                                    Отмена
                                </button>
                            )}
                            <button
                                className={`flex px-4 py-1 w-24 h-7 rounded-full shadow-md text-xs text-white ml-2 items-center justify-center ${isDeleting ? 'bg-[#701F1F]' : 'bg-[#494849]'}  hover:bg-[#701F1F]`}
                                onClick={handleDeleteClick} // Вызвать handleDelete
                                disabled={isDeleting ? true : false}
                            >
                                {isDeleting ? (
                                    <svg
                                        className="animate-spin h-4 w-4 text-white"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                    >
                                        <circle
                                            className="opacity-25"
                                            cx="12"
                                            cy="12"
                                            r="10"
                                            stroke="currentColor"
                                            strokeWidth="4"
                                        ></circle>
                                        <path
                                            className="opacity-75"
                                            fill="currentColor"
                                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                                        ></path>
                                    </svg>
                                ) : (
                                    'Удалить'
                                )}
                            </button>
                        </div>
                    </div>
                </div>
            )}
            {copyPlanPositionPopup && (
                <div
                    className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center"
                    onClick={handleCopyPlanPositionPopup}
                >
                    <div
                        className="p-5 rounded-lg bg-[#262427] w-[600px] max-h-[600px]"
                        onClick={(e) => e.stopPropagation()}
                    >
                        <h2 className="text-white">Копировать позиции?</h2>
                        <div className="flex flex-col mt-2 space-y-1">
                            <label className="text-[12.8px] text-white">
                                Вы точно хотите скопировать позиции?
                            </label>
                            <div className="flex flex-row w-full space-x-5 pt-2">
                                <div className="flex flex-col w-full">
                                    {/* name */}
                                    <div className="flex flex-row gap-1 bg-[#333033] text-[#8A8A8A] text-[10px] px-1 py-2 rounded-t-lg">
                                        <div className="flex w-10 items-center justify-center text-center">
                                            № <br />
                                            П/п
                                        </div>
                                        <div className="flex w-10 items-center justify-center text-center">
                                            Номер позиции в ЕИС
                                        </div>
                                        <div className="flex flex-1 items-center justify-center text-center">
                                            Предмет договора
                                        </div>
                                        <div className="flex w-28 items-center justify-center text-center">
                                            Начальная (максимальная) цена
                                            договора
                                        </div>
                                        <div className="flex w-6 items-center justify-center"></div>
                                    </div>
                                    {/* data */}
                                    <div className="flex flex-col h-[200px] overflow-y-auto gap-1 py-1 px-1 grow bg-[#1D1C1E] rounded-b-lg">
                                        <div className="flex flex-col w-full space-y-1">
                                            {copyItems.map((item, index) => (
                                                <div
                                                    key={item.guid}
                                                    className="flex flex-row w-full rounded bg-[#2A282B] hover:bg-[#373538]"
                                                >
                                                    <div className="flex flex-row w-full">
                                                        <div className="flex flex-row flex-1 gap-1 text-white text-[11px]">
                                                            <div className="flex w-10 items-center justify-center text-[#8A8A8A]">
                                                                {index + 1}
                                                            </div>
                                                            <div className="flex w-10 items-center justify-center">
                                                                {item.ordinal_number
                                                                    ? item.ordinal_number
                                                                    : '-'}
                                                            </div>
                                                            <div className="flex  flex-1 items-center justify-left p-1">
                                                                {item.contract_subject ? (
                                                                    <span className="fourrow">
                                                                        {
                                                                            item.contract_subject
                                                                        }
                                                                    </span>
                                                                ) : (
                                                                    '-'
                                                                )}
                                                            </div>
                                                            <div className="flex w-24 items-center justify-end">
                                                                {Number(
                                                                    item.maximum_contract_price
                                                                ).toLocaleString(
                                                                    'ru-RU',
                                                                    {
                                                                        minimumFractionDigits: 2,
                                                                    }
                                                                )}{' '}
                                                                {item.currency_digital_code
                                                                    ? CURRENCY.get(
                                                                          String(
                                                                              item.currency_digital_code
                                                                          )
                                                                      )
                                                                    : ''}
                                                            </div>
                                                            <div className="flex w-6 items-center justify-center text-[#8A8A8A] "></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-row mt-5 text-right justify-end">
                            {isCopying ? (
                                ''
                            ) : (
                                <button
                                    className="px-4 py-1 rounded-full shadow-md text-xs text-white bg-green-700 hover:bg-green-600"
                                    onClick={handleCopyPlanPositionPopup}
                                >
                                    Отмена
                                </button>
                            )}
                            <button
                                className={`flex px-4 py-1 w-24 h-7 rounded-full shadow-md text-xs text-white ml-2 items-center justify-center ${isCopying ? 'bg-green-700' : 'bg-[#494849]'} bg-green-700`}
                                onClick={handleCopyClick} // Вызвать handleCopy
                                disabled={isCopying ? true : false}
                            >
                                {isCopying ? (
                                    <svg
                                        className="animate-spin h-4 w-4 text-white"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                    >
                                        <circle
                                            className="opacity-25"
                                            cx="12"
                                            cy="12"
                                            r="10"
                                            stroke="currentColor"
                                            strokeWidth="4"
                                        ></circle>
                                        <path
                                            className="opacity-75"
                                            fill="currentColor"
                                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                                        ></path>
                                    </svg>
                                ) : (
                                    'Скопировать'
                                )}
                            </button>
                        </div>
                    </div>
                </div>
            )}
            {statusChangePlanPositionPopup && (
                <div
                    className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center"
                    onClick={() => handleStatusChangePlanPositionPopup('')}
                >
                    <div
                        className="p-5 rounded-lg bg-[#262427] w-[600px] max-h-[600px]"
                        onClick={(e) => e.stopPropagation()}
                    >
                        <h2 className="text-white">Поменять статус позиции?</h2>
                        <div className="flex flex-col mt-2 space-y-1">
                            <label className="text-[12.8px] text-white">
                                Вы точно хотите поменять статус позиции на "
                                {statusChangeFlag}"?
                            </label>
                            <div className="flex flex-row w-full space-x-5 pt-2">
                                <div className="flex flex-col w-full">
                                    {/* name */}
                                    <div className="flex flex-row gap-1 bg-[#333033] text-[#8A8A8A] text-[10px] px-1 py-2 rounded-t-lg">
                                        <div className="flex w-10 items-center justify-center text-center">
                                            № <br />
                                            П/п
                                        </div>
                                        <div className="flex w-10 items-center justify-center text-center">
                                            Номер позиции в ЕИС
                                        </div>
                                        <div className="flex flex-1 items-center justify-center text-center">
                                            Предмет договора
                                        </div>
                                        <div className="flex w-28 items-center justify-center text-center">
                                            Начальная (максимальная) цена
                                            договора
                                        </div>
                                        <div className="flex w-6 items-center justify-center"></div>
                                    </div>
                                    {/* data */}
                                    <div className="flex flex-col h-[200px] overflow-y-auto gap-1 py-1 px-1 grow bg-[#1D1C1E] rounded-b-lg">
                                        <div className="flex flex-col w-full space-y-1">
                                            {statusChangeItems.map(
                                                (item, index) => (
                                                    <div
                                                        key={item.guid}
                                                        className="flex flex-row w-full rounded bg-[#2A282B] hover:bg-[#373538]"
                                                    >
                                                        <div className="flex flex-row w-full">
                                                            <div className="flex flex-row flex-1 gap-1 text-white text-[11px]">
                                                                <div className="flex w-10 items-center justify-center text-[#8A8A8A]">
                                                                    {index + 1}
                                                                </div>
                                                                <div className="flex w-10 items-center justify-center">
                                                                    {item.ordinal_number
                                                                        ? item.ordinal_number
                                                                        : '-'}
                                                                </div>
                                                                <div className="flex  flex-1 items-center justify-left p-1">
                                                                    {item.contract_subject ? (
                                                                        <span className="fourrow">
                                                                            {
                                                                                item.contract_subject
                                                                            }
                                                                        </span>
                                                                    ) : (
                                                                        '-'
                                                                    )}
                                                                </div>
                                                                <div className="flex w-24 items-center justify-end">
                                                                    {Number(
                                                                        item.maximum_contract_price
                                                                    ).toLocaleString(
                                                                        'ru-RU',
                                                                        {
                                                                            minimumFractionDigits: 2,
                                                                        }
                                                                    )}{' '}
                                                                    {item.currency_digital_code
                                                                        ? CURRENCY.get(
                                                                              String(
                                                                                  item.currency_digital_code
                                                                              )
                                                                          )
                                                                        : ''}
                                                                </div>
                                                                <div className="flex w-6 items-center justify-center text-[#8A8A8A] "></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-row mt-5 text-right justify-end">
                            {isStatusChanging ? (
                                ''
                            ) : (
                                <button
                                    className="px-4 py-1 rounded-full shadow-md text-xs text-white bg-green-700 hover:bg-green-600"
                                    onClick={() =>
                                        handleStatusChangePlanPositionPopup('')
                                    }
                                >
                                    Отмена
                                </button>
                            )}
                            <button
                                className={`flex px-4 py-1 w-24 h-7 rounded-full shadow-md text-xs text-white ml-2 items-center justify-center ${isStatusChanging ? 'bg-green-700' : 'bg-[#494849]'} bg-green-700`}
                                onClick={handleStatusChangeClick} // Вызвать handleStatusChange
                                disabled={isStatusChanging ? true : false}
                            >
                                {isStatusChanging ? (
                                    <svg
                                        className="animate-spin h-4 w-4 text-white"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                    >
                                        <circle
                                            className="opacity-25"
                                            cx="12"
                                            cy="12"
                                            r="10"
                                            stroke="currentColor"
                                            strokeWidth="4"
                                        ></circle>
                                        <path
                                            className="opacity-75"
                                            fill="currentColor"
                                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                                        ></path>
                                    </svg>
                                ) : (
                                    'Изменить'
                                )}
                            </button>
                        </div>
                    </div>
                </div>
            )}
            {stageChangePlanPositionPopup && (
                <div
                    className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center"
                    onClick={() => handleStageChangePlanPositionPopup('')}
                >
                    <div
                        className="p-5 rounded-lg bg-[#262427] w-[600px] max-h-[600px]"
                        onClick={(e) => e.stopPropagation()}
                    >
                        <h2 className="text-white">Поменять стадию позиции?</h2>
                        <div className="flex flex-col mt-2 space-y-1">
                            <label className="text-[12.8px] text-white">
                                Вы точно хотите поменять стадию позиции на "
                                {stageChangeFlag}"?
                            </label>
                            <div className="flex flex-row w-full space-x-5 pt-2">
                                <div className="flex flex-col w-full">
                                    {/* name */}
                                    <div className="flex flex-row gap-1 bg-[#333033] text-[#8A8A8A] text-[10px] px-1 py-2 rounded-t-lg">
                                        <div className="flex w-10 items-center justify-center text-center">
                                            № <br />
                                            П/п
                                        </div>
                                        <div className="flex w-10 items-center justify-center text-center">
                                            Номер позиции в ЕИС
                                        </div>
                                        <div className="flex flex-1 items-center justify-center text-center">
                                            Предмет договора
                                        </div>
                                        <div className="flex w-28 items-center justify-center text-center">
                                            Начальная (максимальная) цена
                                            договора
                                        </div>
                                        <div className="flex w-6 items-center justify-center"></div>
                                    </div>
                                    {/* data */}
                                    <div className="flex flex-col h-[200px] overflow-y-auto gap-1 py-1 px-1 grow bg-[#1D1C1E] rounded-b-lg">
                                        <div className="flex flex-col w-full space-y-1">
                                            {stageChangeItems.map(
                                                (item, index) => (
                                                    <div
                                                        key={item.guid}
                                                        className="flex flex-row w-full rounded bg-[#2A282B] hover:bg-[#373538]"
                                                    >
                                                        <div className="flex flex-row w-full">
                                                            <div className="flex flex-row flex-1 gap-1 text-white text-[11px]">
                                                                <div className="flex w-10 items-center justify-center text-[#8A8A8A]">
                                                                    {index + 1}
                                                                </div>
                                                                <div className="flex w-10 items-center justify-center">
                                                                    {item.ordinal_number
                                                                        ? item.ordinal_number
                                                                        : '-'}
                                                                </div>
                                                                <div className="flex  flex-1 items-center justify-left p-1">
                                                                    {item.contract_subject ? (
                                                                        <span className="fourrow">
                                                                            {
                                                                                item.contract_subject
                                                                            }
                                                                        </span>
                                                                    ) : (
                                                                        '-'
                                                                    )}
                                                                </div>
                                                                <div className="flex w-24 items-center justify-end">
                                                                    {Number(
                                                                        item.maximum_contract_price
                                                                    ).toLocaleString(
                                                                        'ru-RU',
                                                                        {
                                                                            minimumFractionDigits: 2,
                                                                        }
                                                                    )}{' '}
                                                                    {item.currency_digital_code
                                                                        ? CURRENCY.get(
                                                                              String(
                                                                                  item.currency_digital_code
                                                                              )
                                                                          )
                                                                        : ''}
                                                                </div>
                                                                <div className="flex w-6 items-center justify-center text-[#8A8A8A] "></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-row mt-5 text-right justify-end">
                            {isStageChanging ? (
                                ''
                            ) : (
                                <button
                                    className="px-4 py-1 rounded-full shadow-md text-xs text-white bg-green-700 hover:bg-green-600"
                                    onClick={() =>
                                        handleStageChangePlanPositionPopup('')
                                    }
                                >
                                    Отмена
                                </button>
                            )}
                            <button
                                className={`flex px-4 py-1 w-24 h-7 rounded-full shadow-md text-xs text-white ml-2 items-center justify-center ${isStageChanging ? 'bg-green-700' : 'bg-[#494849]'} bg-green-700`}
                                onClick={handleStageChangeClick} // Вызвать handleStageChange
                                disabled={isStageChanging ? true : false}
                            >
                                {isStageChanging ? (
                                    <svg
                                        className="animate-spin h-4 w-4 text-white"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                    >
                                        <circle
                                            className="opacity-25"
                                            cx="12"
                                            cy="12"
                                            r="10"
                                            stroke="currentColor"
                                            strokeWidth="4"
                                        ></circle>
                                        <path
                                            className="opacity-75"
                                            fill="currentColor"
                                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                                        ></path>
                                    </svg>
                                ) : (
                                    'Изменить'
                                )}
                            </button>
                        </div>
                    </div>
                </div>
            )}
            {toEisSendPopup && (
                <div
                    className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center"
                    onClick={handleToEisSendPopup}
                >
                    <div
                        className="p-5 rounded-lg bg-[#262427] w-[600px] max-h-[600px]"
                        onClick={(e) => e.stopPropagation()}
                    >
                        <h2 className="text-white">
                            Отправка готовых позиций в ЕИС
                        </h2>
                        <div className="flex flex-col mt-2 space-y-1">
                            <div className="flex flex-col w-full space-y-1">
                                <div>
                                    <label className="text-[12.8px] text-[#8A8A8A]">
                                        Обоснование внесения изменений
                                    </label>
                                </div>
                                <div>
                                    <input
                                        className="org-input org-input-placeholder"
                                        name="modification_description"
                                        value={
                                            toEisSendInfo.modification_description
                                        }
                                        onChange={(e) =>
                                            handleInputChange(
                                                'modification_description',
                                                e.target.value
                                            )
                                        }
                                        placeholder="Введите..."
                                    />
                                </div>
                            </div>
                            <div className="flex flex-col w-full space-y-1">
                                <div>
                                    <label className="text-[12.8px] text-[#8A8A8A]">
                                        Дополнительные сведения
                                    </label>
                                </div>
                                <div>
                                    <input
                                        className="org-input org-input-placeholder"
                                        name="additional_info"
                                        value={toEisSendInfo.additional_info}
                                        onChange={(e) =>
                                            handleInputChange(
                                                'additional_info',
                                                e.target.value
                                            )
                                        }
                                        placeholder="Введите..."
                                    />
                                </div>
                            </div>
                            <div className="flex flex-col w-full space-y-1">
                                <div>
                                    <label className="text-[12.8px] text-[#8A8A8A]">
                                        Дата утверждения плана
                                    </label>
                                </div>
                                <div className="flex w-full space-x-5 ">
                                    <div className="flex flex-1 w-full ">
                                        <input
                                            type="date"
                                            className="org-input"
                                            name="approve_date"
                                            value={toEisSendInfo.approve_date}
                                            onChange={(e) =>
                                                handleInputChange(
                                                    'approve_date',
                                                    e.target.value
                                                )
                                            }
                                        />
                                    </div>
                                    <div className="flex flex-1 w-full"></div>
                                    <div className="flex flex-1 w-full"></div>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-row mt-5 text-right justify-end">
                            {isToEisSending ? (
                                ''
                            ) : (
                                <button
                                    className="px-4 py-1 rounded-full shadow-md text-xs text-white bg-green-700 hover:bg-green-600"
                                    onClick={handleToEisSendPopup}
                                >
                                    Отмена
                                </button>
                            )}
                            <button
                                className={`flex px-4 py-1 w-42 h-7 rounded-full shadow-md text-xs text-white ml-2 items-center justify-center ${isToEisSending ? 'bg-green-700' : 'bg-[#494849]'} bg-green-700`}
                                onClick={handleToEisSendClick} // Вызвать handleStageChange
                                disabled={isToEisSending ? true : false}
                            >
                                {isToEisSending ? (
                                    <svg
                                        className="animate-spin h-4 w-4 text-white"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                    >
                                        <circle
                                            className="opacity-25"
                                            cx="12"
                                            cy="12"
                                            r="10"
                                            stroke="currentColor"
                                            strokeWidth="4"
                                        ></circle>
                                        <path
                                            className="opacity-75"
                                            fill="currentColor"
                                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                                        ></path>
                                    </svg>
                                ) : (
                                    'Отправить в ЕИС'
                                )}
                            </button>
                        </div>
                    </div>
                </div>
            )}
            {updateFromEisPopup && (
                <div
                    className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center"
                    onClick={handleUpdateFromEisPopup}
                >
                    <div
                        className="p-5 rounded-lg bg-[#262427] w-[400px] max-h-[600px]"
                        onClick={(e) => e.stopPropagation()}
                    >
                        <div className="flex flex-row gap-2 items-center justify-left">
                            <h2 className="text-white">Обновление плана</h2>
                        </div>
                        <div className="flex flex-col mt-2 space-y-1">
                            <div className="flex flex-row gap-2 items-center justify-left">
                                <div className="flex flex-row w-5 h-5 items-center justify-center text-white">
                                    <svg
                                        id="info-circle"
                                        className="w-5 h-5"
                                        data-name="Layer 1"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 24 24"
                                    >
                                        <path
                                            fill="#FFBF00"
                                            d="M12,2A10,10,0,1,0,22,12,10.01114,10.01114,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8.00917,8.00917,0,0,1,12,20Zm0-8.5a1,1,0,0,0-1,1v3a1,1,0,0,0,2,0v-3A1,1,0,0,0,12,11.5Zm0-4a1.25,1.25,0,1,0,1.25,1.25A1.25,1.25,0,0,0,12,7.5Z"
                                        ></path>
                                    </svg>
                                </div>
                                <label className="text-[12.8px] text-white">
                                    {popupMessage}
                                </label>
                            </div>
                        </div>
                        <div className="flex flex-row mt-5 text-right justify-end">
                            {isUpdatingFromEis ? (
                                ''
                            ) : (
                                <button
                                    className="px-4 py-1 rounded-full shadow-md text-xs text-white bg-[#494849] hover:bg-[#656365]"
                                    onClick={handleUpdateFromEisPopup}
                                >
                                    Отмена
                                </button>
                            )}
                            <button
                                className={`flex px-4 py-1 w-24 h-7 rounded-full shadow-md text-xs text-white ml-2 items-center justify-center ${!isUpdatingFromEis ? 'bg-green-700 hover:bg-green-600' : 'bg-[#494849]'} `}
                                onClick={() =>
                                    handleForceUpdate(
                                        plan[0]?.registration_number
                                    )
                                } // Вызвать handleForceUpdate
                                disabled={isUpdatingFromEis ? true : false}
                            >
                                {isUpdatingFromEis ? (
                                    <svg
                                        className="animate-spin h-4 w-4 text-white"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                    >
                                        <circle
                                            className="opacity-25"
                                            cx="12"
                                            cy="12"
                                            r="10"
                                            stroke="currentColor"
                                            strokeWidth="4"
                                        ></circle>
                                        <path
                                            className="opacity-75"
                                            fill="currentColor"
                                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                                        ></path>
                                    </svg>
                                ) : (
                                    'Обновить'
                                )}
                            </button>
                        </div>
                    </div>
                </div>
            )}
            {takeFromEisPopup && (
                <div
                    className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center"
                    onClick={handleTakeFromEisPopup}
                >
                    <div
                        className="p-5 rounded-lg bg-[#262427] w-[400px] max-h-[400px]"
                        onClick={(e) => e.stopPropagation()}
                    >
                        <h2 className="text-white">Связывание плана</h2>
                        <div className="flex flex-col mt-2 space-y-1">
                            <label className="text-[12.8px] text-white">
                                Номер плана закупки в ЕИС
                            </label>
                            <input
                                type="text"
                                className="w-full h-7 px-2 rounded-md border-0 text-sm bg-[#181818] text-white focus:outline-none focus:ring-1 focus:ring-[#ffffff8f]"
                                value={takePlanFromEisInfo.registration_number} // Привязка значения
                                onChange={(e) =>
                                    handleInputPlanNumberChange(
                                        'registration_number',
                                        e.target.value
                                    )
                                } // Обновление состояния
                                maxLength={10} // Ограничение на уровне HTML
                            />
                            {popupMessage != '' ? (
                                <span
                                    style={{
                                        color: '#C15C5C',
                                        fontSize: '12.8px',
                                    }}
                                >
                                    {popupMessage}
                                </span>
                            ) : (
                                ''
                            )}
                        </div>
                        <div className="flex flex-row mt-5 text-right justify-end">
                            {isTakingFromEis ? (
                                ''
                            ) : (
                                <button
                                    className="px-4 py-1 rounded-full shadow-md text-xs text-white bg-green-700 hover:bg-green-600"
                                    onClick={handleTakeFromEisPopup}
                                >
                                    Отмена
                                </button>
                            )}
                            <button
                                className={`flex px-4 py-1 w-24 h-7 rounded-full shadow-md text-xs text-white ml-2 items-center justify-center ${isTakingFromEis ? 'bg-green-700' : 'bg-[#494849]'} bg-green-700`}
                                onClick={handleTakeFromEisClick} // Вызвать handleForceUpdate
                                disabled={isTakingFromEis ? true : false}
                            >
                                {isTakingFromEis ? (
                                    <svg
                                        className="animate-spin h-4 w-4 text-white"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                    >
                                        <circle
                                            className="opacity-25"
                                            cx="12"
                                            cy="12"
                                            r="10"
                                            stroke="currentColor"
                                            strokeWidth="4"
                                        ></circle>
                                        <path
                                            className="opacity-75"
                                            fill="currentColor"
                                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                                        ></path>
                                    </svg>
                                ) : (
                                    'Загрузить'
                                )}
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default PlanPositionListPage
