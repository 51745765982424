import Section from '../../../../../../components/Section'
import { PlanPositionRow } from '../types'
import PurchaseItemsTable from './PurchaseItemsTable'
import React, { useState } from 'react'

type LayoutProps = {
    positionRows: PlanPositionRow[]
}

const CHECKBOX_CLASS = `shrink-0 
    relative w-11 h-6 bg-gray-200 dark:border-gray-600 dark:bg-gray-700 
    rounded-full peer peer-focus:ring-1 peer-focus:ring-blue-300 
    dark:peer-focus:ring-blue-800 peer-checked:after:translate-x-full 
    peer-checked:after:border-white peer-checked:bg-blue-600 after:content-[''] 
    after:absolute after:top-0.5 after:start-[2px] 
    after:bg-white after:border-gray-300 after:border after:rounded-full 
    after:h-5 after:w-5 after:transition-all`

const PurchaseItemsSection: React.FC<LayoutProps> = ({ positionRows }) => {
    const [isRegionForAll, setIsRegionForAll] = useState(false)

    return (
        <Section title="Информация о закупаемых товарах, работах, услугах">
            <label className="block">
                <span className="block mt-2 text-sm text-secondary text-zinc-500">
                    Способ указания региона поставки
                </span>
            </label>

            <div className="inline-flex">
                <div>
                    <label className="flex flex-row items-center cursor-pointer">
                        <input
                            type="checkbox"
                            className="peer appearance-none"
                            checked={isRegionForAll}
                            onChange={() => setIsRegionForAll(!isRegionForAll)}
                        />
                        <div className={CHECKBOX_CLASS}></div>
                        <span className="form-label ml-2">
                            Единое для всех товаров, работ, услуг
                        </span>
                    </label>
                </div>

                <div className="md:ml-5">
                    <label className="flex flex-row items-center cursor-pointer">
                        <input
                            type="checkbox"
                            className="peer appearance-none"
                            checked={!isRegionForAll}
                            onChange={() => setIsRegionForAll(!isRegionForAll)}
                        />
                        <div className={CHECKBOX_CLASS}></div>
                        <span className="form-label ml-2">
                            Отдельное для каждого товара, работы, услуги
                        </span>
                    </label>
                </div>
            </div>

            <PurchaseItemsTable
                positionRows={positionRows}
                showRegion={!isRegionForAll}
            />
        </Section>
    )
}

export default PurchaseItemsSection
